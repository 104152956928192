import React, { useState } from "react";
import { useEffect } from 'react';
import { Button, Card, Col, Container, Form, FormGroup, Row, Spinner, Table } from 'react-bootstrap';
import noImage from '../../../assets/images/no-logo.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { updateUserState } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { json } from "is";

const TopUpZendIt = ({ operators, number, numberOperatorId, numberOperatorName, onBack, numberOperatorDetails, userDetails, fullNumber, country, countryDiscount }) => {
    const dispatch = useDispatch();
    const [numbersOperator, setNumbersOperator] = useState('N/A');
    const [operatorDetailsLoading, setOperatorDetailsLoading] = useState(false);
    const [amountLimitError, setAmountLimitError] = useState("");
    const [destinationCurrencySymbol, setDestinationCurrencySymbol] = useState(false);
    const [minAmount, setMinAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [selectedOperator, setSelectedOperator] = useState({});
    const [receivedAmount, setReceivedAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [topUpAmount, setTopUpAmount] = useState(0);
    const [commission, setCommission] = useState(0);
    const [amountExcludeAdminCommission, setAmountExcludeAdminCommission] = useState(0);
    const [isReceiverReadOnly, setIsReceiverReadOnly] = useState(false);
    const [topLoading, setTopLoading] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(false);
    const [offerId, setOfferId] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState(false);
    const [adminMarginAmount, setAdminMarginAmount] = useState(0);
    const [vendorCommission, setVendorCommission] = useState(0);


    useEffect(() => {
        if (operators?.length > 0 && numberOperatorId) {
            var numberData = {};
            numberData.logo = "";
            numberData.name = numberOperatorId.brand;
            numberData.operatorId = 0;
            numberData.discount = 0;
            setNumbersOperator(numberData);
            onOperatorClick(numberOperatorId.brand);
        }
    }, [operators, numberOperatorId, numberOperatorDetails]);

    useEffect(() => {
        setCommission(userDetails?.default_gain);
    }, [userDetails]);

    if (!operators) return null; // Ensure we don't render if no operator data is available
    const goBack = (e) => {
        e.preventDefault();
        setSelectedOperator({});
        setReceivedAmount('');
        setTotalAmount('');
        setTopUpAmount('');
        setTopLoading(false);
        setCommission('0');
        setNumbersOperator('N/A');
        onBack();
        setOperatorDetailsLoading(false);
    }
    const onOperatorClick = (name) => {

        setOperatorDetailsLoading(true);
        const selectOperator = operators.find(x => x.name == name);
        axios.post(process.env.REACT_APP_API_URL + '/get-operator-details', selectOperator)
            .then((res) => {
                if (res?.status) {
                    setAmountLimitError("");
                    if (selectOperator?.response) {
                        var opdetails = JSON.parse(selectOperator.response);
                        setDestinationCurrencySymbol(opdetails.send.currency);
                    }
                    if (numberOperatorDetails.brand == res?.data?.data?.name) {
                        var numberData = {};
                        numberData.name = res?.data?.data?.name;
                        numberData.logo = "";
                        numberData.discount = res?.data?.data?.discount;
                        setNumbersOperator(numberData);
                    }
                    else {
                        var numberData = {};
                        numberData.name = res?.data?.data?.name;
                        numberData.discount = res?.data?.data?.discount;
                        setNumbersOperator(numberData);
                    }

                    if (res?.data?.data.amount_type == "RANGE") {
                        setIsReceiverReadOnly(false);
                    } else {
                        setIsReceiverReadOnly(true);
                    }
                    setSelectedOperator(res?.data?.data);
                    setReceivedAmount(0);
                    setTotalAmount(0);
                    setTopUpAmount(0);
                    setOperatorDetailsLoading(false);
                } else {
                    setOperatorDetailsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setOperatorDetailsLoading(false);
            })
    }
    const getDiscountAmount = (discount, topupAmount) => {
        var discountAmount = 0;
        if (discount > 0) {
            discountAmount = parseInt(discount);
        } else {
            discountAmount = parseInt(countryDiscount);
        }
        setDiscountPercentage(discountAmount);
        var discountedAmount = (discountAmount / 100) * topupAmount;
        setDiscountAmount(discountedAmount);
        var updatedAmount = topupAmount + discountedAmount;
        return updatedAmount;
    }
    const getDiscountAmountForReceiverAmount = (discount, topupAmount) => {
        var discountAmount = 0;
        if (discount > 0) {
            discountAmount = parseInt(discount);
        } else {
            discountAmount = parseInt(countryDiscount);
        }
        setDiscountPercentage(discountAmount);
        var discountedAmount = (discountAmount / 100) * topupAmount;
        setDiscountAmount(discountedAmount);
        var updatedAmount = topupAmount - discountedAmount;
        return updatedAmount;
    }
    const calculateAdminMarginAmount = (orginalAmount) => {
        if (selectedOperator?.margin > 0) {
            var adminMargin = ((selectedOperator?.margin / 100) * orginalAmount);
            setAdminMarginAmount(adminMargin);
        }
    }
    const amountChangeHandler = (e, getAmount = null, sku = null, margin = null, originalAmount = 0, receiveValue = 0, discount = 0, cost = 0) => {
        if (sku !== null && sku !== '') {
            setOfferId(sku);
        } else {
            setOfferId(selectedOperator?.sku_code);
        }
        if (getAmount !== null && getAmount !== '') {
            setVendorCommission(originalAmount - cost);
            calculateAdminMarginAmount(originalAmount);
            const topAmount = parseFloat(getAmount);
            setIsReceiverReadOnly(true);
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {

                const amountWithCommission = parseFloat(getAmount) * (parseFloat(commission) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(getAmount);
                var amountToBeReceived = calculateAmountRecievedAsPerVendor(originalAmount, originalAmount, userDetails?.gainType, receiveValue, sku);
                setTotalAmount(amountTotal);
                setAmountExcludeAdminCommission(originalAmount);
                setTopUpAmount(validateAmountField(getAmount));
                setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
            } else {
                var amountToBeReceived = calculateAmountRecievedAsPerVendor(originalAmount, originalAmount, userDetails?.gainType, receiveValue, sku);
                const amountTotal = parseFloat(commission) + parseFloat(getAmount);
                setTotalAmount(amountTotal);
                setAmountExcludeAdminCommission(originalAmount);
                setTopUpAmount(validateAmountField(getAmount));
                setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
            }
        } else {
            if (e.target.value) {
                const topAmount = parseFloat(e.target.value);
                calculateAdminMarginAmount(topAmount);
                const discount = parseFloat(parseFloat(e.target.dataset.discount).toFixed(2));
                const selectedTopUpAmount = getDiscountAmount(discount, topAmount);
                setIsReceiverReadOnly(false);
                if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {

                    // const topAmount = parseFloat(e.target.value);
                    if (selectedOperator?.margin == 0) {

                        if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            setAmountLimitError("");
                        }
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(selectedTopUpAmount, originalAmount, userDetails?.gainType, receiveValue);
                        const commissionRate = (parseFloat(commission) / 100) * parseFloat(topAmount);
                        const amountTotal = parseFloat(topAmount) + commissionRate;
                        setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(selectedTopUpAmount.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));

                    } else {

                        const minAmount = parseFloat(parseFloat(e.target.dataset.minamount).toFixed(2));
                        const maxAmount = parseFloat(parseFloat(e.target.dataset.maxamount).toFixed(2));

                        let adminMargin = 0;
                        setAmountLimitError("");
                        if (minAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminminmargin);
                        } else if (maxAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminmaxmargin);
                        } else if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            adminMargin = ((selectedOperator?.margin / 100) * topAmount);
                        }
                        const amountAfterAdminMargin = topAmount - adminMargin;
                        const amountAfterDiscountAmount = getDiscountAmount(discount, amountAfterAdminMargin);
                        const commissionRate = (parseFloat(commission) / 100) * parseFloat(topAmount);
                        const amountTotal = parseFloat(topAmount) + commissionRate;
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(topAmount, originalAmount, userDetails?.gainType, receiveValue);
                        setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(amountAfterDiscountAmount.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));
                    }
                }
                else {

                    if (selectedOperator?.margin == 0) {
                        const amountTotal = parseFloat(commission) + parseFloat(topAmount);
                        setTotalAmount(amountTotal);
                        if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            setAmountLimitError("");
                            var amountToBeReceived = calculateAmountRecievedAsPerVendor(selectedTopUpAmount, selectedTopUpAmount, userDetails?.gainType);
                            setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        }
                        setAmountExcludeAdminCommission(selectedTopUpAmount.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));

                    } else {

                        const minAmount = parseFloat(parseFloat(e.target.dataset.minamount).toFixed(2));
                        const maxAmount = parseFloat(parseFloat(e.target.dataset.maxamount).toFixed(2));

                        let adminMargin = 0;
                        if (minAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminminmargin);
                            setAmountLimitError("");
                        } else if (maxAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminmaxmargin);
                            setAmountLimitError("");
                        } else if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            adminMargin = ((selectedOperator?.margin / 100) * topAmount);
                            setAmountLimitError("");
                        }
                        const amountAfterAdminMargin = topAmount - adminMargin;
                        const amountAfterDiscountAmount = getDiscountAmount(discount, amountAfterAdminMargin);
                        const amountTotal = parseFloat(commission) + parseFloat(topAmount);
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(amountAfterDiscountAmount, amountAfterDiscountAmount, userDetails?.gainType);
                        if (amountToBeReceived) {
                            setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        }
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(amountAfterDiscountAmount.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));
                    }
                }
            } else {
                setReceivedAmount(0);
                setTotalAmount(0);
                setTopUpAmount(null);
            }
        }
    }
    const onCommissionChangeHandler = (e) => {
        if (topUpAmount !== '' && topUpAmount !== null) {
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                const amountWithCommission = parseFloat(topUpAmount) * (parseFloat(e.target.value) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                setCommission(validateAmountField(e.target.value));
                setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
            } else {
                const amountTotal = parseFloat(e.target.value) + parseFloat(topUpAmount);
                setCommission(validateAmountField(e.target.value));
                setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
            }
        } else {
            setCommission(validateAmountField(e.target.value));
        }
    }
    const onReceivedAmountChangeHandler = (e) => {

    }
    const checkValidation = () => {
        if (JSON.parse(selectedOperator?.response)?.fixedAmounts?.length > 0) {

            if (JSON.parse(selectedOperator?.response)?.fixedAmounts?.includes(parseFloat(amountExcludeAdminCommission))) {
                if (parseFloat(amountExcludeAdminCommission) <= userDetails?.balance) {
                    return true;
                } else {
                    Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                    return false;
                }
            } else {
                Swal.fire('Ooops!', 'Please enter a amount from these ' + JSON.parse(selectedOperator?.response)?.fixedAmounts?.map(amount => ' ' + amount) + '.', 'error');
                return false;
            }


        } else if (JSON.parse(selectedOperator?.response)?.maxAmount !== null && JSON.parse(selectedOperator?.response)?.minAmount !== null) {

            if (parseFloat(topUpAmount) > (JSON.parse(selectedOperator?.response)?.maxAmount + (JSON.parse(selectedOperator?.response)?.maxAmount * (selectedOperator?.margin / 100)))) {
                Swal.fire('Ooops!', 'Please enter amount less than or equal to ' + (JSON.parse(selectedOperator?.response)?.maxAmount + (JSON.parse(selectedOperator?.response)?.maxAmount * (selectedOperator?.margin / 100))) + '.', 'error');
                return false;
            } else if (parseFloat(topUpAmount) < (JSON.parse(selectedOperator?.response)?.minAmount + (JSON.parse(selectedOperator?.response)?.minAmount * (selectedOperator?.margin / 100))).toFixed(2)) {
                Swal.fire('Ooops!', 'Please enter amount greater than or equal to ' + (JSON.parse(selectedOperator?.response)?.minAmount + (JSON.parse(selectedOperator?.response)?.minAmount * (selectedOperator?.margin / 100))).toFixed(2) + '.', 'error');
                return false;
            } else {
                if (parseFloat(topUpAmount) <= userDetails?.balance) {
                    return true;
                } else {
                    Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                    return false;
                }
            }
        } else if (amountLimitError != "") {
            Swal.fire('Ooops!', 'Amount cannot exceed the minimum or maximum allowed value', 'error');
            return false;
        }

        return true;
    }
    const validateAmountField = (value) => {
        value = Number(value);
        if (isNaN(value) || value < 0) {
            value = 0;
        }
        return value;
    }

    const onTopUpSubmitHandler = () => {
        const validate = checkValidation();

        if (validate) {
            setAmountLimitError("");
            const data = {
                id: selectedOperator?.id,
                gateway_id: selectedOperator?.vendor_id,
                user_id: userDetails?.user_id,
                phone: number,
                fullNumber: fullNumber,
                totalAmount: totalAmount,
                countryCode: country,
                receivedAmount: receivedAmount,
                amountWithAdminCommission: topUpAmount,
                amount: amountExcludeAdminCommission,
                discountAmount: discountAmount,
                offerId: offerId,
                receiverAmountCurrency: destinationCurrencySymbol,
                adminMargin: selectedOperator?.margin,
                adminMarginAmount: adminMarginAmount,
                resellerGain: (totalAmount - amountExcludeAdminCommission),
                vendorCommission: vendorCommission,
                vendorCommissionPercentage: 0
            }

            setTopLoading(true);
            axios.post(process.env.REACT_APP_API_URL + '/get-topup', data)
                .then((res) => {
                    if (res?.data?.status == 'success') {
                        setTopLoading(false);
                        Swal.fire('Done!', res.data.message, 'success').then(() => {
                            dispatch(updateUserState(res?.data?.data));
                            setReceivedAmount(0);
                            setTotalAmount(0);
                            setTopUpAmount(0);
                            setSelectedOperator(selectedOperator);
                        });
                    } else {
                        setTopLoading(false);
                        Swal.fire('Oops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err, "err");
                    setTopLoading(false);
                    Swal.fire('Oops!', err.response.data.message, 'error');
                });
        }
    }
    const calculateAmountRecievedAsPerVendor = (getAmount, originalAmount = 0, gainType, receiveValue = 0, sku = 0) => {
        var amountToBeReceived = 0;
        if (selectedOperator?.amount_type == "FIXED") {
            if (getAmount > 0) {

                amountToBeReceived = receiveValue;
            }
        }
        return amountToBeReceived;
    }
    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Top Up Details
                        </Card.Title>
                        <Button type="button" className="float-right mt-3" variant="btn btn-primary loginBtn" onClick={(e) => goBack(e)}>Back</Button>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6} className="d-flex align-items-center">
                                {numbersOperator?.logo && (
                                    <img
                                        src={numbersOperator.logo}
                                        alt="Operator Logo"
                                        className="img-fluid mr-2"
                                        style={{ height: "80px" }}
                                    />
                                )}
                                <div>
                                    <h4 className="mb-1">{numbersOperator.name}</h4>
                                    <h4 className="mb-0">{number}</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md={3}>
                                <label>Choose Operator: </label>
                            </Col>
                            <Col md={9}>
                                <div className='operators-main-box'>
                                    {operators?.length > 0 ? operators?.map((operator, index) => {
                                        return (
                                            <div className={numbersOperator.name == operator?.name ? 'operator-box active' : 'operator-box'} key={index} onClick={() => onOperatorClick(operator?.name)}>{operator?.name}</div>
                                        )
                                    }) : null}
                                </div>
                            </Col>
                        </Row>


                        {operatorDetailsLoading ?
                            <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                            :
                            Object.keys(selectedOperator).length > 0 ?
                                JSON.parse(selectedOperator?.response)?.priceType === "RANGE" ? (
                                    <Row className='mt-3 align-items-center'>
                                        <Col md={3}>
                                            <label>Amount &euro; ({JSON.parse(selectedOperator?.response)?.price?.min}/{JSON.parse(selectedOperator?.response)?.price?.max}) </label>
                                        </Col>
                                        <Col md={9}>
                                            <input className='form-control' type='number' onChange={(e) => amountChangeHandler(e)} value={topUpAmount} />
                                            <label style={{ color: "red" }}>
                                                {amountLimitError}
                                            </label>
                                        </Col>
                                    </Row>
                                ) :
                                    <Row className='mt-3'>
                                        <Col md={3}>
                                            <label>Choose Amount: </label>
                                        </Col>
                                        <Col md={9}>
                                            <div className='amount-main-box'>
                                                {selectedOperator?.prices?.length > 0 ? selectedOperator?.prices?.map((price, index) => {
                                                    let exactAmount = ((price?.price?.fixed + ((price?.price?.fixed * parseFloat(price?.margin)) / 100)) / 100).toFixed(2);
                                                    let sellingAmount = (parseFloat(price?.price?.fixed) / 100);
                                                    let recAmount = (parseFloat(price?.send?.fixed) / 100);
                                                    let cost = (parseFloat(price?.cost?.fixed) / 100);
                                                    return (
                                                        <div onClick={(e) => amountChangeHandler(e, exactAmount, price?.offerId, price?.margin, sellingAmount, recAmount, 0, cost)} className={topUpAmount == ((price?.price?.fixed + ((price?.price?.fixed * parseFloat(price?.margin)) / 100)) / 100).toFixed(2) ? 'amount-box active' : 'amount-box'} key={index}>{exactAmount} EUR</div>
                                                    )
                                                }) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                : null}
                        {userDetails?.gainType == 1 || userDetails?.gainType == '1' ?
                            <Row className='mt-3 align-items-center'>
                                <Col md={3}>
                                    <label>Commission (in Percentage %)</label>
                                </Col>
                                <Col md={9}>
                                    <input className='form-control' value={parseFloat(commission)} onChange={onCommissionChangeHandler} />
                                </Col>
                            </Row>
                            :
                            <Row className='mt-3 align-items-center'>
                                <Col md={3}>
                                    <label>Commission (in Euro &euro;)</label>
                                </Col>
                                <Col md={9}>
                                    <input className='form-control' value={parseFloat(parseFloat(commission).toFixed(2))} onChange={onCommissionChangeHandler} />
                                </Col>
                            </Row>
                        }
                        <Row className='mt-3 align-items-center'>
                            <Col md={3}>
                                <label>Total Amount (in Euro &euro;)</label>
                            </Col>
                            <Col md={9}>
                                <input className='form-control' value={totalAmount == 0 ? totalAmount : parseFloat(totalAmount).toFixed(2)} readOnly />
                            </Col>
                        </Row>
                        <Row className='mt-3 align-items-center'>
                            <Col md={3}>
                                <label>Receiver Amount (in {destinationCurrencySymbol})</label>
                            </Col>
                            <Col md={9}>
                                <input
                                    className="form-control"
                                    value={receivedAmount}
                                    onChange={(e) => onReceivedAmountChangeHandler(e)}
                                    placeholder="Enter amount"
                                    readOnly={isReceiverReadOnly}
                                    data-discount={selectedOperator?.discount}
                                />
                            </Col>

                        </Row>
                        <Button type="button" className="float-right mt-3" variant="btn btn-primary loginBtn" onClick={onTopUpSubmitHandler}>
                            {topLoading ? (
                                <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}Loading...</>
                            ) : ('Submit')}
                        </Button>



                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default TopUpZendIt;
