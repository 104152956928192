export const loginSuccess = userData => {
    return {
        type: 'LOGIN_SUCCESS',
        payload: userData,
    };
};

export const loginWith2FASuccess = userData => {
    return {
        type: 'LOGIN_SUCCESS_WITH_2FA',
        payload: userData,
    };
};

export const logoutUser = () => ({
    type: 'LOGOUT',
});

export const registrationSuccess = userData => {
    return {
        type: 'REGISTER_SUCCESS',
        // payload: userData
    };
}

export const twoFactorStatusChange = userData => {
    return {
        type: 'TWO_FACTOR_STATUS_CHANGE_SUCCESS',
        payload: userData
    }
}

export const resetTwoFactor = userData => {
    return {
        type: 'RESET_TWO_FACTOR_SUCCESS',
        payload: userData
    }
}

export const setDefaultGain = userData => {
    return {
        type: 'SET_DEFAULT_GAIN_SUCCESS',
        payload: userData
    }
}

export const updateProfile = userData => {
    return {
        type: 'UPDATE_PROFILE_SUCCESS',
        payload: userData
    }
}

export const verifyOTP = userData => {
    return {
        type: 'VERIFY_OTP',
        payload: userData
    }
}

export const updateUserState = userData => {
    return {
        type: 'UPDATE_USER_STATE',
        payload: userData
    }
}

export const updateAdminProfile = userData => {
    return {
        type: 'UPDATE_ADMIN_PROFILE_SUCCESS',
        payload: userData
    }
}

export const updateUserBalance = (newBalance) => {
    return {
        type: "UPDATE_USER_BALANCE",
        payload: newBalance,
    };
};
