import axios from 'axios';
import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, FormGroup, Row, Spinner, Table } from 'react-bootstrap';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { updateUserState } from '../../../store/actions';
import { useEffect } from 'react';
import TopUpReloadly from './TopUpReloadly';
import TopUpDingConnect from './TopUpDingConnect';
import TopUpZendIt from './TopUpZendIt';
import TopUpPreyPay from './TopUpPreyPay';

const TopUp = () => {
    const dispatch = useDispatch();
    const [number, setNumber] = useState('');
    const [fullNumber, setFullNumber] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [country, setCountry] = useState(null);
    const [countryIso, setCountryIso] = useState(null);
    const [showOperators, setShowOperators] = useState(false);
    const [operators, setOperators] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState({});

    const [receivedAmount, setReceivedAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [topUpAmount, setTopUpAmount] = useState(0);
    const [amountExcludeAdminCommission, setAmountExcludeAdminCommission] = useState(0);
    const [getOperatorLoading, setGetOperatorLoading] = useState(false);
    const [topLoading, setTopLoading] = useState(false);
    const [recAmountLoading, setRecAmountLoading] = useState(false);
    const [amountRecLoading, setAmountRecLoading] = useState(false);
    const [commission, setCommission] = useState(0);
    const [numbersOperator, setNumbersOperator] = useState('N/A');
    const [operatorDetailsLoading, setOperatorDetailsLoading] = useState(false);
    const [offerId, setOfferId] = useState('');
    const [carrier, setCarrier] = useState(null);
    const userDetails = useSelector(state => state?.login?.user?.user?.UserDetailModel);
    const [isReceiverReadOnly, setIsReceiverReadOnly] = useState(false);

    const [amountLimitError, setAmountLimitError] = useState("");
    const [minAmount, setMinAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [prepayExchangeRate, setPrepayExchangeRate] = useState(0);
    const [showReloadlyComponent, setShowReloadlyComponent] = useState(false);
    const [showDingConnectComponent, setShowDingConnectComponent] = useState(false);
    const [showZendItComponent, setShowZendItComponent] = useState(false);
    const [showPrepayComponent, setShowPrepayComponent] = useState(false);
    const [numberOperatorDetails, setNumberOperatorDetails] = useState(false);
    const [countryDiscount, setCountryDiscount] = useState(false);

    useEffect(() => {
        setCommission(userDetails?.default_gain);
    }, [userDetails]);

    // useEffect(() => {
    //     onOperatorClick(carrier);
    // }, [carrier]);

    const validateAmountField = (value) => {
        value = Number(value);
        if (isNaN(value) || value < 0) {
            value = 0;
        }
        return value;
    }

    const onNumberChangeHandler = (isValid, value, selectedCountryData, fullNumber) => {
        const country = selectedCountryData.name.split('(')[0];
        if (selectedCountryData?.dialCode) {
            setNumber(value);
            setFullNumber(fullNumber);
            setIsValid(true);
            setCountry(country);
            setCountryIso(selectedCountryData.iso2);
        } else {
            setIsValid(false);
        }

    }

    const onSubmitHandler = () => {

        if (isValid) {

            setGetOperatorLoading(true);
            setReceivedAmount('');
            setTotalAmount('');
            setSelectedOperator({});
            setCommission(userDetails?.default_gain);
            const data = { country: country, number: fullNumber, countryIsoCode: countryIso };
            axios.post(process.env.REACT_APP_API_URL + '/get-operators', data)
                .then((res) => {
                    setGetOperatorLoading(false);
                    if (res?.data?.status) {
                        setCountryDiscount(res?.data?.countryDiscount);
                        if (res?.data?.numberDetails) {
                            setShowOperators(true);
                            setOperators(res?.data?.data);
                            setCarrier(res?.data?.numberDetails);
                            setNumbersOperator(res?.data?.numberDetails?.name);
                            setNumberOperatorDetails(res?.data?.numberDetails);

                            if (res?.data?.data[0].default_vendor == 1) {
                                setShowReloadlyComponent(true);
                            } else if (res?.data?.data[0].default_vendor == 2) {
                                setShowDingConnectComponent(true);
                            }
                            else if (res?.data?.data[0].default_vendor == 3) {
                                setShowZendItComponent(true);
                            }
                            else if (res?.data?.data[0].default_vendor == 4) {
                                setShowPrepayComponent(true);
                            }
                        } else {
                            setNumber('');
                            setFullNumber(null);
                            Swal.fire('Ooops!', "We are unable to get your number details, please confirm your number again", 'error');
                        }

                    } else {

                        Swal.fire('Ooops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    setGetOperatorLoading(false);
                    console.log(err, "err");
                });
        } else {
            Swal.fire('Ooops!', 'Please enter a valid number.', 'error');
        }
    }

    const haveCommonWords = (str1, str2) => {
        // Split strings into words
        const words1 = str1.split(' ');
        const words2 = str2.split(' ');
        // Check for any common substrings
        for (let word1 of words1) {
            for (let word2 of words2) {
                if (word1.includes(word2) || word2.includes(word1)) {
                    return true; // Common substring found
                }
            }
        }

        return false; // No common substrings
    }


    const requestForRecvAmountCalculatonForDingConnect = (sendValue, sendCurrencyIso, skuCode, default_vendor) => {
        setRecAmountLoading(true);
        const data = {
            sendValue: sendValue,
            sendCurrencyIso: sendCurrencyIso,
            skuCode: skuCode,
            default_vendor: default_vendor,
            receiverAmount: 0
        }
        axios.post(process.env.REACT_APP_API_URL + '/get-estimate-price', data)
            .then((res) => {
                if (res?.data?.status) {
                    setRecAmountLoading(false);
                    const recAmountItem = res?.data?.data?.Items;

                    const recAmount = recAmountItem[0].Price.ReceiveValue;
                    setReceivedAmount(validateAmountField(recAmount.toFixed(2)));
                } else {
                    setRecAmountLoading(false);
                    Swal.fire('Oops!', res.data.message, 'error');
                }
            })
            .catch((err) => {
                console.log(err, "err");
                setRecAmountLoading(false);
                Swal.fire('Oops!', err, 'error');
            });
    }

    const getAmountUsingRecvAmountForDingConnect = (sendCurrencyIso, skuCode, default_vendor, receiverAmount) => {
        setAmountRecLoading(true);
        const data = {
            sendValue: 0,
            sendCurrencyIso: sendCurrencyIso,
            skuCode: skuCode,
            default_vendor: default_vendor,
            receiverAmount: receiverAmount
        }
        axios.post(process.env.REACT_APP_API_URL + '/get-estimate-price', data)
            .then((res) => {
                if (res?.data?.status) {
                    setAmountRecLoading(false);
                    const recAmountItem = res?.data?.data?.Items;
                    if (recAmountItem[0] && recAmountItem[0].hasOwnProperty('Price')) {
                        const recAmount = recAmountItem[0].Price.ReceiveValue;
                        const topUpAmount = recAmountItem[0].Price.SendValue;
                        const amountTotal = parseFloat(commission) + parseFloat(topUpAmount);
                        setReceivedAmount(validateAmountField(recAmount.toFixed(2)));
                        setTopUpAmount(validateAmountField(topUpAmount.toFixed(2)));
                        setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
                        setAmountLimitError("");
                    } else {
                        if (
                            recAmountItem[0] &&
                            recAmountItem[0].ErrorCodes &&
                            Array.isArray(recAmountItem[0].ErrorCodes) &&
                            recAmountItem[0].ErrorCodes.some(error => error.Code === 'ParameterOutOfRange')
                        ) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        }
                    }

                } else {
                    setAmountRecLoading(false);
                    Swal.fire('Oops!', "unable to get the value", 'error');
                }
            })
            .catch((err) => {
                console.log(err, "err");
                setAmountRecLoading(false);
                Swal.fire('Oops!', err, 'error');
            });
    }

    const requestForRecvAmountCalculatonForPrePay = (sendValue, skuCode, default_vendor) => {
        setRecAmountLoading(true);
        const data = {
            sendValue: sendValue,
            skuCode: skuCode,
            default_vendor: default_vendor,
            receiverAmount: 0
        }
        axios.post(process.env.REACT_APP_API_URL + '/get-estimate-price', data)
            .then((res) => {
                if (res?.data?.status) {
                    setRecAmountLoading(false);
                    const recAmountItem = res?.data?.data?.payLoad;
                    // setPrepayExchangeRate(recAmountItem.exchangeRate);
                    var recAmount = sendValue * recAmountItem.exchangeRate;
                    setReceivedAmount(validateAmountField(recAmount.toFixed(2)));

                } else {
                    setRecAmountLoading(false);
                    setPrepayExchangeRate(0);
                    Swal.fire('Oops!', res.data.message, 'error');
                }
            })
            .catch((err) => {
                console.log(err, "err");
                setRecAmountLoading(false);
                setPrepayExchangeRate(0);
                Swal.fire('Oops!', err, 'error');
            });
    }

    const getAmountUsingRecvAmountForPreyPay = (sendValue, skuCode, default_vendor, isRecv = false) => {
        setRecAmountLoading(true);
        const data = {
            sendValue: sendValue,
            skuCode: skuCode,
            default_vendor: default_vendor,
            receiverAmount: 0
        }
        axios.post(process.env.REACT_APP_API_URL + '/get-estimate-price', data)
            .then((res) => {
                if (res?.data?.status) {
                    setRecAmountLoading(false);
                    const recAmountItem = res?.data?.data?.payLoad;
                    setPrepayExchangeRate(recAmountItem.exchangeRate);
                    var prepayExchangeRate = recAmountItem.exchangeRate;
                    if (!isRecv) {
                        var recAmount = sendValue * prepayExchangeRate;
                        setReceivedAmount(validateAmountField(recAmount.toFixed(2)));
                        const amountWithCommission = parseFloat(recAmount) * (parseFloat(commission) / 100);
                        const amountTotal = parseFloat(amountWithCommission) + parseFloat(recAmount);
                        setTotalAmount(amountTotal);
                        setAmountLimitError("");
                    } else {
                        var recAmount = sendValue / prepayExchangeRate;
                        if (recAmount < minAmount || recAmount > maxAmount) {
                            document.getElementById("recAmountButtonCalcFor4").innerHTML = "";
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            setTopUpAmount(validateAmountField(recAmount.toFixed(2)));
                            const amountWithCommission = parseFloat(recAmount) * (parseFloat(commission) / 100);
                            const amountTotal = parseFloat(amountWithCommission) + parseFloat(recAmount);
                            setTotalAmount(amountTotal);
                            setAmountLimitError("");
                        }
                    }

                } else {
                    setRecAmountLoading(false);
                    setPrepayExchangeRate(0);
                    Swal.fire('Oops!', res.data.message, 'error');
                }
            })
            .catch((err) => {
                console.log(err, "err");
                setRecAmountLoading(false);
                setPrepayExchangeRate(0);
                Swal.fire('Oops!', err, 'error');
            });
    }
    const calculateAmountRecievedAsPerVendor = (getAmount, originalAmount = 0, gainType, receiveValue = 0, sku = 0) => {

        var amountToBeReceived = 0;
        if (selectedOperator.default_vendor == 2) {

            if (gainType == "1") {
                if (selectedOperator?.amount_type == "FIXED") {
                    selectedOperator.prices.map((price) => {
                        if (price.Maximum.SendValue == originalAmount) {
                            amountToBeReceived = price.Maximum.ReceiveValue;
                            return;
                        }
                    })
                } else {
                    document.getElementById("amountRecButtonCalc").innerHTML = "";
                    const recAmountButtonCalc = document.getElementById("recAmountButtonCalc");
                    if (recAmountButtonCalc) {
                        const existingAnchor = recAmountButtonCalc.querySelector("a");
                        if (existingAnchor) {
                            existingAnchor.remove();
                        }
                    }
                    if (getAmount > 0) {
                        // Create a new anchor tag
                        const anchor = document.createElement("a");
                        anchor.innerText = "Click Here to get estimated receiver amount";
                        anchor.href = "#"; // Use "#" if you don't want the page to navigate
                        anchor.style.cursor = "pointer"; // Optional: Add pointer cursor for a clickable look
                        anchor.style.color = "red"; // Optional: Style the anchor

                        // Assign the onclick event to the new anchor
                        anchor.onclick = (e) => {
                            e.preventDefault(); // Prevent navigation
                            requestForRecvAmountCalculatonForDingConnect(
                                getAmount,
                                "EUR",
                                selectedOperator.sku_code,
                                selectedOperator.default_vendor
                            );
                        };
                        // Append the new anchor to the container
                        document.getElementById("recAmountButtonCalc").appendChild(anchor);
                    } else {
                        document.getElementById("recAmountButtonCalc").innerHTML = "";
                    }
                }
            } else {
                if (selectedOperator?.amount_type == "FIXED") {
                    if (getAmount > 0) {
                        amountToBeReceived = receiveValue;
                    }
                } else {
                    console.log("dingcon=", getAmount);
                    document.getElementById("amountRecButtonCalc").innerHTML = "";
                    const recAmountButtonCalc = document.getElementById("recAmountButtonCalc");
                    if (recAmountButtonCalc) {
                        const existingAnchor = recAmountButtonCalc.querySelector("a");
                        if (existingAnchor) {
                            existingAnchor.remove();
                        }
                    }
                    if (getAmount > 0) {
                        // Create a new anchor tag
                        const anchor = document.createElement("a");
                        anchor.innerText = "Click Here to get estimated receiver amount";
                        anchor.href = "#"; // Use "#" if you don't want the page to navigate
                        anchor.style.cursor = "pointer"; // Optional: Add pointer cursor for a clickable look
                        anchor.style.color = "red"; // Optional: Style the anchor

                        // Assign the onclick event to the new anchor
                        anchor.onclick = (e) => {
                            e.preventDefault(); // Prevent navigation
                            requestForRecvAmountCalculatonForDingConnect(
                                getAmount,
                                "EUR",
                                selectedOperator.sku_code,
                                selectedOperator.default_vendor
                            );
                        };
                        // Append the new anchor to the container
                        document.getElementById("recAmountButtonCalc").appendChild(anchor);
                    } else {
                        document.getElementById("recAmountButtonCalc").innerHTML = "";
                    }
                }
            }
        } else if (selectedOperator.default_vendor == 1) {
            if (selectedOperator?.amount_type == "FIXED") {

                amountToBeReceived = originalAmount * parseFloat(selectedOperator?.fx_rates);
            } else {
                console.log("range", parseFloat(selectedOperator?.fx_rates));
                amountToBeReceived = getAmount * parseFloat(selectedOperator?.fx_rates);
            }
        } else if (selectedOperator.default_vendor == 3) {
            if (selectedOperator?.amount_type == "FIXED") {
                if (getAmount > 0) {

                    amountToBeReceived = receiveValue;
                }
            }
        } else if (selectedOperator.default_vendor == 4) {

            if (selectedOperator?.amount_type == "FIXED") {

                if (receiveValue > 0) {
                    amountToBeReceived = receiveValue;
                }
            } else {
                const recAmountButtonCalc = document.getElementById("recAmountButtonCalcFor4");
                if (recAmountButtonCalc) {
                    const existingAnchor = recAmountButtonCalc.querySelector("a");
                    if (existingAnchor) {
                        existingAnchor.remove();
                    }
                }
                if (getAmount > 0) {

                    // Create a new anchor tag
                    const anchor = document.createElement("a");
                    anchor.innerText = "Click Here to get estimated receiver amount";
                    anchor.href = "#"; // Use "#" if you don't want the page to navigate
                    anchor.style.cursor = "pointer"; // Optional: Add pointer cursor for a clickable look
                    anchor.style.color = "red"; // Optional: Style the anchor

                    // Assign the onclick event to the new anchor
                    anchor.onclick = (e) => {
                        e.preventDefault(); // Prevent navigation
                        getAmountUsingRecvAmountForPreyPay(
                            getAmount,
                            selectedOperator.sku_code,
                            selectedOperator.default_vendor
                        );
                    };
                    // Append the new anchor to the container
                    document.getElementById("recAmountButtonCalcFor4").appendChild(anchor);

                    // if (prepayExchangeRate) {
                    //     console.log("getAmount", prepayExchangeRate);
                    //     var recAmount = getAmount * prepayExchangeRate;
                    //     amountToBeReceived =validateAmountField(recAmount.toFixed(2));
                    //     // setReceivedAmount();
                    // } else {

                    // }

                } else {
                    document.getElementById("recAmountButtonCalcFor4").innerHTML = "";
                }
            }
        }
        return amountToBeReceived;
    }

    const amountChangeHandler = (e, getAmount = null, sku = null, margin = null, originalAmount = 0, receiveValue = 0) => {

        if (sku !== null && sku !== '') {
            setOfferId(sku);
        } else {
            setOfferId(selectedOperator?.sku_code);
        }

        if (getAmount !== null && getAmount !== '') {
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                const topAmount = parseFloat(getAmount);
                const amountWithCommission = parseFloat(topAmount) * (parseFloat(commission) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(topAmount);
                var amountToBeReceived = calculateAmountRecievedAsPerVendor(getAmount, originalAmount, userDetails?.gainType, receiveValue, sku);
                setTotalAmount(amountTotal);
                setAmountExcludeAdminCommission(originalAmount);
                setIsReceiverReadOnly(true);
                setTopUpAmount(validateAmountField(topAmount));
                setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
            } else {
                const topAmount = parseFloat(getAmount);
                var amountToBeReceived = calculateAmountRecievedAsPerVendor(getAmount, originalAmount, userDetails?.gainType, receiveValue, sku);
                const amountTotal = parseFloat(commission) + parseFloat(topAmount);
                setTotalAmount(amountTotal);
                setAmountExcludeAdminCommission(originalAmount);
                setIsReceiverReadOnly(true);
                setTopUpAmount(validateAmountField(topAmount));
                setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
            }
        } else {
            if (e.target.value) {
                console.log("here1");
                if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {

                    const topAmount = parseFloat(e.target.value);
                    if (selectedOperator?.margin == 0) {
                        // if (selectedOperator.default_vendor == 4) {
                        if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            setAmountLimitError("");
                        }
                        const amountAfterAdminCommission = topAmount;
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(topAmount, originalAmount, userDetails?.gainType, receiveValue);
                        const commissionRate = (parseFloat(commission) / 100) * parseFloat(topAmount);
                        const amountTotal = parseFloat(topAmount) + commissionRate;
                        setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(amountAfterAdminCommission.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));

                        // } else {
                        //     console.log("here2");
                        //     const amountAfterAdminCommission = topAmount;
                        //     const amountToBeReceived = amountAfterAdminCommission * parseFloat(selectedOperator?.fx_rates);
                        //     const commissionRate = (parseFloat(commission) / 100) * parseFloat(topAmount);
                        //     const amountTotal = parseFloat(topAmount) + commissionRate;
                        //     setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        //     setTotalAmount(amountTotal);
                        //     setAmountExcludeAdminCommission(amountAfterAdminCommission.toFixed(2));
                        //     setTopUpAmount(validateAmountField(topAmount));
                        // }

                    } else {

                        const minAmount = parseFloat(parseFloat(e.target.dataset.minamount).toFixed(2));
                        const maxAmount = parseFloat(parseFloat(e.target.dataset.maxamount).toFixed(2));

                        let adminMargin = 0;
                        setAmountLimitError("");
                        if (minAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminminmargin);
                        } else if (maxAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminmaxmargin);
                        } else if (topAmount < minAmount || topAmount > maxAmount) {
                            if (selectedOperator.default_vendor == 4) {
                                document.getElementById("recAmountButtonCalcFor4").innerHTML = "";
                            }
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            adminMargin = ((selectedOperator?.margin / 100) * topAmount);
                        }

                        const amountAfterAdminCommission = topAmount - adminMargin;
                        const commissionRate = (parseFloat(commission) / 100) * parseFloat(topAmount);
                        const amountTotal = parseFloat(topAmount) + commissionRate;
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(amountAfterAdminCommission, originalAmount, userDetails?.gainType, receiveValue);
                        // var amountToBeReceived = amountAfterAdminCommission * parseFloat(selectedOperator?.fx_rates);
                        setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));

                        // if (amountLimitError == "" && (selectedOperator.default_vendor == 4 || selectedOperator.default_vendor == 2)) {
                        //     calculateAmountRecievedAsPerVendor(e.target.value, originalAmount, userDetails?.gainType, receiveValue);
                        // } else {
                        //     var amountToBeReceived = amountAfterAdminCommission * parseFloat(selectedOperator?.fx_rates);
                        //     setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        // }
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(amountAfterAdminCommission.toFixed(2));
                        setIsReceiverReadOnly(false);
                        setTopUpAmount(validateAmountField(topAmount));

                    }
                } else {
                    // const topAmount = parseFloat(e.target.value);
                    // const amountAfterAdminCommission = topAmount - (topAmount * (parseFloat(margin !== null ? margin : selectedOperator?.margin) / 100));
                    const topAmount = parseFloat(e.target.value);

                    if (selectedOperator?.margin == 0) {
                        const amountAfterAdminCommission = topAmount;
                        // const amountToBeReceived = amountAfterAdminCommission * parseFloat(selectedOperator?.fx_rates);
                        const amountTotal = parseFloat(commission) + parseFloat(topAmount);
                        setTotalAmount(amountTotal);
                        if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            setAmountLimitError("");
                            if (selectedOperator.default_vendor == 4) {
                                calculateAmountRecievedAsPerVendor(amountAfterAdminCommission, amountAfterAdminCommission, userDetails?.gainType);
                            } else {

                                var amountToBeReceived = calculateAmountRecievedAsPerVendor(amountAfterAdminCommission, amountAfterAdminCommission, userDetails?.gainType);
                                setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                            }
                        }
                        setAmountExcludeAdminCommission(amountAfterAdminCommission.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));

                    } else {

                        const minAmount = parseFloat(parseFloat(e.target.dataset.minamount).toFixed(2));
                        const maxAmount = parseFloat(parseFloat(e.target.dataset.maxamount).toFixed(2));

                        let adminMargin = 0;
                        if (minAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminminmargin);
                            setAmountLimitError("");
                        } else if (maxAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminmaxmargin);
                            setAmountLimitError("");
                        } else if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            adminMargin = ((selectedOperator?.margin / 100) * topAmount);
                            setAmountLimitError("");
                        }
                        const amountAfterAdminCommission = topAmount - adminMargin;
                        // const amountToBeReceived = amountAfterAdminCommission * parseFloat(selectedOperator?.fx_rates);
                        const amountTotal = parseFloat(commission) + parseFloat(topAmount);
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(amountAfterAdminCommission, amountAfterAdminCommission, userDetails?.gainType);
                        if (amountToBeReceived) {
                            setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        }
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(amountAfterAdminCommission.toFixed(2));
                        setIsReceiverReadOnly(false);
                        setTopUpAmount(validateAmountField(topAmount));
                    }
                }
            } else {
                setReceivedAmount(0);
                setTotalAmount(0);
                setTopUpAmount(null);
            }
        }
    }

    const onTopUpSubmitHandler = () => {
        const validate = checkValidation();

        if (validate) {
            setAmountLimitError("");
            if (selectedOperator?.amount_type == "RANGE" && (topUpAmount < minAmount || topUpAmount > maxAmount)) {

                setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
            } else {
                const data = {
                    id: selectedOperator?.id,
                    gateway_id: selectedOperator?.vendor_id,
                    user_id: userDetails?.user_id,
                    phone: number,
                    fullNumber: fullNumber,
                    totalAmount: totalAmount,
                    countryCode: country,
                    receivedAmount: receivedAmount,
                    amountWithAdminCommission: topUpAmount,
                    amount: amountExcludeAdminCommission,
                    offerId: offerId
                }
                setTopLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/get-topup', data)
                    .then((res) => {
                        if (res?.data?.status == 'success') {
                            setTopLoading(false);
                            Swal.fire('Done!', res.data.message, 'success').then(() => {
                                dispatch(updateUserState(res?.data?.data));
                                setReceivedAmount(0);
                                setTotalAmount(0);
                                setSelectedOperator({});
                                setNumber('');
                                setFullNumber(null);
                                setIsValid(false);
                                setCountry(null);
                                setShowOperators(false);
                            });
                        } else {
                            setTopLoading(false);
                            Swal.fire('Oops!', res.data.message, 'error');
                        }
                    })
                    .catch((err) => {
                        console.log(err, "err");
                        setTopLoading(false);
                        Swal.fire('Oops!', err.response.data.message, 'error');
                    });
            }

        }
    }

    const checkValidation = () => {

        if (JSON.parse(selectedOperator?.response)?.Minimum) {

            if (amountExcludeAdminCommission < JSON.parse(selectedOperator?.response)?.Minimum?.SendValue) {
                Swal.fire('Ooops!', 'Please enter amount greater than or equal to ' + JSON.parse(selectedOperator?.response)?.Minimum?.SendValue + '.', 'error');
                return false;
            } else if (amountExcludeAdminCommission > JSON.parse(selectedOperator?.response)?.Maximum?.SendValue) {
                Swal.fire('Ooops!', 'Please enter amount less than or equal to ' + JSON.parse(selectedOperator?.response)?.Maximum?.SendValue + '.', 'error');
                return false;
            } else {
                // if (receivedAmount > 0) {
                if (amountExcludeAdminCommission <= userDetails?.balance) {
                    return true;
                } else {
                    Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                    return false;
                }
                // } else {
                //     Swal.fire('Ooops!', 'Please enter valid amount.', 'error');
                //     return false;
                // }
            }
        } else if (JSON.parse(selectedOperator?.response)?.fixedAmounts?.length > 0) {

            if (JSON.parse(selectedOperator?.response)?.fixedAmounts?.includes(parseFloat(amountExcludeAdminCommission))) {
                // if (receivedAmount > 0) {

                // } else {
                //     Swal.fire('Ooops!', 'Please enter valid amount.', 'error');
                //     return false;
                // }
                if (parseFloat(amountExcludeAdminCommission) <= userDetails?.balance) {
                    return true;
                } else {
                    Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                    return false;
                }
            } else {
                Swal.fire('Ooops!', 'Please enter a amount from these ' + JSON.parse(selectedOperator?.response)?.fixedAmounts?.map(amount => ' ' + amount) + '.', 'error');
                return false;
            }


        } else if (JSON.parse(selectedOperator?.response)?.maxAmount !== null && JSON.parse(selectedOperator?.response)?.minAmount !== null) {

            if (parseFloat(topUpAmount) > (JSON.parse(selectedOperator?.response)?.maxAmount + (JSON.parse(selectedOperator?.response)?.maxAmount * (selectedOperator?.margin / 100)))) {
                Swal.fire('Ooops!', 'Please enter amount less than or equal to ' + (JSON.parse(selectedOperator?.response)?.maxAmount + (JSON.parse(selectedOperator?.response)?.maxAmount * (selectedOperator?.margin / 100))) + '.', 'error');
                return false;
            } else if (parseFloat(topUpAmount) < (JSON.parse(selectedOperator?.response)?.minAmount + (JSON.parse(selectedOperator?.response)?.minAmount * (selectedOperator?.margin / 100))).toFixed(2)) {
                Swal.fire('Ooops!', 'Please enter amount greater than or equal to ' + (JSON.parse(selectedOperator?.response)?.minAmount + (JSON.parse(selectedOperator?.response)?.minAmount * (selectedOperator?.margin / 100))).toFixed(2) + '.', 'error');
                return false;
            } else {
                if (parseFloat(topUpAmount) <= userDetails?.balance) {
                    return true;
                } else {
                    Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                    return false;
                }
                // if (receivedAmount > 0) {

                // } else {
                //     Swal.fire('Ooops!', 'Please enter valid amount.', 'error');
                //     return false;
                // }
            }
        } else if (amountLimitError != "") {
            Swal.fire('Ooops!', 'Amount cannot exceed the minimum or maximum allowed value', 'error');
            return false;
        }

        return true;
    }

    const onCommissionChangeHandler = (e) => {
        if (topUpAmount !== '' && topUpAmount !== null) {
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                // const topUpAmount = e.target.value;
                // const amountToBeReceived = parseFloat(topUpAmount) * parseFloat(selectedOperator?.max_value);
                const amountWithCommission = parseFloat(topUpAmount) * (parseFloat(e.target.value) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                // setReceivedAmount(amountToBeReceived.toFixed(2));
                setCommission(validateAmountField(e.target.value));
                setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
            } else {
                // const amountToBeReceived = parseFloat(topUpAmount) * parseFloat(selectedOperator?.max_value);
                const amountTotal = parseFloat(e.target.value) + parseFloat(topUpAmount);
                // setReceivedAmount(amountToBeReceived.toFixed(2));
                setCommission(validateAmountField(e.target.value));
                setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
            }
        } else {
            setCommission(validateAmountField(e.target.value));
        }
    }

    const onReceivedAmountChangeHandler = (e) => {
        if (e.target.value) {

            if (selectedOperator.default_vendor == 1) {
                if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                    const expectedChargeAmount = e.target.value;
                    const topUpAmount = parseFloat(expectedChargeAmount) / parseFloat(selectedOperator?.fx_rates);
                    setAmountLimitError("");
                    if (topUpAmount < minAmount || topUpAmount > maxAmount) {
                        setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                    }
                    const amountWithCommission = parseFloat(topUpAmount) * (parseFloat(commission) / 100);
                    const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                    setReceivedAmount(validateAmountField(expectedChargeAmount));
                    setTopUpAmount(validateAmountField(topUpAmount.toFixed(2)));
                    setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
                } else {
                    const expectedChargeAmount = e.target.value;
                    const topUpAmount = parseFloat(expectedChargeAmount) / parseFloat(selectedOperator?.fx_rates);
                    const amountTotal = parseFloat(commission) + parseFloat(topUpAmount);
                    setReceivedAmount(validateAmountField(expectedChargeAmount));
                    setTopUpAmount(validateAmountField(topUpAmount.toFixed(2)));
                    setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
                }
            } else if (selectedOperator.default_vendor == 2) {
                document.getElementById("recAmountButtonCalc").innerHTML = "";
                const expectedChargeAmount = e.target.value;
                const existingAnchor = document.getElementById("amountRecButtonCalc").querySelector("a");
                if (existingAnchor) {
                    // Remove the existing anchor if it exists
                    existingAnchor.remove();
                }
                if (expectedChargeAmount > 0) {
                    // Create a new anchor tag
                    const anchor = document.createElement("a");
                    anchor.innerText = "Click Here to get estimated Amount";
                    anchor.href = "#"; // Use "#" if you don't want the page to navigate
                    anchor.style.cursor = "pointer"; // Optional: Add pointer cursor for a clickable look
                    anchor.style.color = "red"; // Optional: Style the anchor

                    // Assign the onclick event to the new anchor
                    anchor.onclick = (e) => {
                        e.preventDefault(); // Prevent navigation
                        getAmountUsingRecvAmountForDingConnect(
                            "",
                            selectedOperator.sku_code,
                            selectedOperator.default_vendor,
                            expectedChargeAmount
                        );
                    };
                    // Append the new anchor to the container
                    document.getElementById("amountRecButtonCalc").appendChild(anchor);
                } else {
                    document.getElementById("amountRecButtonCalc").innerHTML = "";
                }
                setReceivedAmount(e.target.value);

            } else if (selectedOperator.default_vendor == 4) {
                if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                    const expectedChargeAmount = e.target.value;
                    if (prepayExchangeRate) {
                        var topUpAmount = parseFloat(expectedChargeAmount) / prepayExchangeRate;
                        setTopUpAmount(validateAmountField(topUpAmount.toFixed(2)));

                        if (topUpAmount < minAmount || topUpAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            setAmountLimitError("");
                        }
                        const amountWithCommission = parseFloat(topUpAmount) * (parseFloat(commission) / 100);
                        const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                        setTopUpAmount(validateAmountField(topUpAmount.toFixed(2)));
                        setTotalAmount(amountTotal);
                        document.getElementById("recAmountButtonCalcFor4").innerHTML = "";
                    } else {
                        const existingAnchor = document.getElementById("amountRecButtonCalc").querySelector("a");
                        if (existingAnchor) {
                            // Remove the existing anchor if it exists
                            existingAnchor.remove();
                        }
                        if (expectedChargeAmount > 0) {
                            // Create a new anchor tag
                            const anchor = document.createElement("a");
                            anchor.innerText = "Click Here to get estimated Amount";
                            anchor.href = "#"; // Use "#" if you don't want the page to navigate
                            anchor.style.cursor = "pointer"; // Optional: Add pointer cursor for a clickable look
                            anchor.style.color = "red"; // Optional: Style the anchor

                            // Assign the onclick event to the new anchor
                            anchor.onclick = (e) => {
                                e.preventDefault(); // Prevent navigation
                                getAmountUsingRecvAmountForPreyPay(
                                    e.target.value,
                                    selectedOperator.sku_code,
                                    selectedOperator.default_vendor,
                                    true
                                );
                            };
                            // Append the new anchor to the container
                            document.getElementById("amountRecButtonCalc").appendChild(anchor);
                        } else {
                            document.getElementById("amountRecButtonCalc").innerHTML = "";
                        }
                    }

                } else {

                    document.getElementById("recAmountButtonCalcFor4").innerHTML = "";
                    const expectedChargeAmount = e.target.value;
                    if (prepayExchangeRate) {
                        var recAmount = expectedChargeAmount / prepayExchangeRate;
                        setTopUpAmount(validateAmountField(recAmount.toFixed(2)));
                    }
                    else {
                        const existingAnchor = document.getElementById("amountRecButtonCalc").querySelector("a");
                        if (existingAnchor) {
                            // Remove the existing anchor if it exists
                            existingAnchor.remove();
                        }
                        if (expectedChargeAmount > 0) {
                            // Create a new anchor tag
                            const anchor = document.createElement("a");
                            anchor.innerText = "Click Here to get estimated Amount";
                            anchor.href = "#"; // Use "#" if you don't want the page to navigate
                            anchor.style.cursor = "pointer"; // Optional: Add pointer cursor for a clickable look
                            anchor.style.color = "red"; // Optional: Style the anchor

                            // Assign the onclick event to the new anchor
                            anchor.onclick = (e) => {
                                e.preventDefault(); // Prevent navigation
                                getAmountUsingRecvAmountForPreyPay(
                                    e.target.value,
                                    selectedOperator.sku_code,
                                    selectedOperator.default_vendor
                                );
                            };
                            // Append the new anchor to the container
                            document.getElementById("amountRecButtonCalc").appendChild(anchor);
                        } else {
                            document.getElementById("amountRecButtonCalc").innerHTML = "";
                        }
                    }
                }
                setReceivedAmount(e.target.value);
            }
        } else {
            setReceivedAmount('');
            setTotalAmount('');
            setTopUpAmount('');
            setAmountLimitError("");
        }
    }

    const onBack = () => {
        setNumber('');
        setFullNumber(null);
        setIsValid(false);
        setCountry(null);
        setShowOperators(false);
        setShowReloadlyComponent(false);
        setShowDingConnectComponent(false);
        setShowZendItComponent(false);
        setShowPrepayComponent(false);
        setOperators([]);
        setSelectedOperator({});
        setReceivedAmount('');
        setTotalAmount('');
        setTopUpAmount('');
        setGetOperatorLoading(false);
        setTopLoading(false);
        setCommission('0');
        setNumbersOperator('N/A');
        setOperatorDetailsLoading(false);
        setOfferId('');
        setPrepayExchangeRate('');
    }

    return (
        <section className="resellerProfile">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Top Up</h4>
                            </div>

                        </div>
                    </Col>
                </Row>
                {showReloadlyComponent && setOperators && (
                    <TopUpReloadly
                        operators={operators}
                        number={number}
                        numberOperatorId={carrier}
                        numberOperatorName={numbersOperator}
                        onBack={onBack}
                        numberOperatorDetails={numberOperatorDetails}
                        userDetails={userDetails}
                        fullNumber={fullNumber}
                        country={country}
                        countryDiscount={countryDiscount}
                    />
                )}
                {showDingConnectComponent && setOperators && (
                    <TopUpDingConnect
                        operators={operators}
                        number={number}
                        numberOperatorId={carrier}
                        numberOperatorName={numbersOperator}
                        onBack={onBack}
                        numberOperatorDetails={numberOperatorDetails}
                        userDetails={userDetails}
                        fullNumber={fullNumber}
                        country={country}
                        countryDiscount={countryDiscount}
                    />
                )}
                {showZendItComponent && setOperators && (
                    <TopUpZendIt
                        operators={operators}
                        number={number}
                        numberOperatorId={carrier}
                        numberOperatorName={numbersOperator}
                        onBack={onBack}
                        numberOperatorDetails={numberOperatorDetails}
                        userDetails={userDetails}
                        fullNumber={fullNumber}
                        country={country}
                        countryDiscount={countryDiscount}
                    />
                )}
                {showPrepayComponent && setOperators && (
                    <TopUpPreyPay
                        operators={operators}
                        number={number}
                        numberOperatorId={carrier}
                        numberOperatorName={numbersOperator}
                        onBack={onBack}
                        numberOperatorDetails={numberOperatorDetails}
                        userDetails={userDetails}
                        fullNumber={fullNumber}
                        country={country}
                        countryDiscount={countryDiscount}
                    />
                )}
                {!showOperators ?
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>
                                        Enter Mobile Number
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form className="row g-3 date-icon-set-modal">
                                        <div className="col-md-10">
                                            {/* <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Mobile Number</Form.Label> */}
                                            <IntlTelInput
                                                containerClassName="intl-tel-input"
                                                inputClassName="form-control"
                                                onPhoneNumberChange={onNumberChangeHandler}
                                                value={number}
                                                defaultCountry={countryIso ?? "us"}
                                            />
                                        </div>
                                        <Button type="button" className="float-right" variant="btn btn-primary loginBtn" onClick={onSubmitHandler}>
                                            {getOperatorLoading ? (
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    {' '}Loading...
                                                </>
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Form>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> : null}
            </Container>
        </section >
    );
}
export default TopUp;
