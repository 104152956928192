import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Navbar, Nav, Dropdown, Button, Form, Spinner } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { getDarkMode } from '../../../../store/mode'
import { connect } from "react-redux";
import { logoutUser } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserBalance } from '../../../../store/actions'
import axios from 'axios';
//img

import user1 from '../../../../assets/images/user/1.jpg'
import user2 from '../../../../assets/images/user/2.jpg'
import noImage from '../../../../assets/images/no-image.png'
import logo2 from '../../../../assets/images/logo2.png';

// flag
import flag1 from '../../../../assets/images/Flag/flag001.png'
import flag3 from '../../../../assets/images/Flag/flag-03.png'
import flag4 from '../../../../assets/images/Flag/flag-04.png'
import flag2 from '../../../../assets/images/Flag/flag-02.png'
import flag5 from '../../../../assets/images/Flag/flag-05.png'
import flag6 from '../../../../assets/images/Flag/flag-06.png'

import '../../../../assets/css/nav-custom.css';

//chnage-mode
import ChangeMode from '../../../Change-Mode'
function mapStateToProps(state) {

    return {
        darkMode: getDarkMode(state)
    };
}
const HeaderStyle1 = (props) => {
    let history = useHistory()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const minisidbar = () => {
        document.body.classList.toggle('sidebar-main')
    }
    const handleLogout = () => {
        props.logoutUser(); // Dispatch logout action
        // Redirect user to login page after logout
        history.push('/');
    };

    // useEffect(() => {
    // if (props?.user?.user?.user?.userModel?.id) {
    //     fetchBalance(); // Fetch initially

    //     const interval = setInterval(() => {
    //         fetchBalance();
    //     }, 30000); // Auto-update every 30 seconds

    //     return () => clearInterval(interval); // Cleanup on unmount
    // }
    // }, [props?.user?.user?.user?.userModel?.id]); // Dependency array


    const fetchBalance = () => {
        setLoading(true);
        const token = props.user.user.token;
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + "/resellers/wallet/get-balance/" + props?.user?.user?.user?.userModel?.id, { headers })
            .then((res) => {
                if (res.data.status) {
                    dispatch(updateUserBalance(res.data.balance));
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.error("Error fetching balance:", err);
            })
    }
    return (
        <>
            <div className="iq-top-navbar">
                <div className="iq-navbar-custom">
                    <Navbar expand="lg" className="navbar-light p-0">
                        <div className="side-menu-bt-sidebar" onClick={minisidbar}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </div>
                        <div className='navbar-header-center-logo'>
                            <img src={logo2} />
                        </div>
                        <div className="d-flex align-items-center">

                            {!props?.user?.user?.user?.userModel?.is_admin ?
                                <div className='mr-1' style={{ fontSize: 'large', fontWeight: '600' }}>Your Balance: &euro; {parseFloat(props?.user?.user?.user?.UserDetailModel?.balance).toFixed(2)}</div> : null
                            }
                            {!props?.user?.user?.user?.userModel?.is_admin ?
                                <Link onClick={fetchBalance} to="#" className='mr-2'>
                                    {loading ? (
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="text-secondary"
                                            width="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <polyline points="23 4 23 10 17 10" />
                                            <polyline points="1 20 1 14 7 14" />
                                            <path d="M3.51 9a9 9 0 0114.13-3M20.49 15a9 9 0 01-14.13 3" />
                                        </svg>

                                    )}
                                </Link> : null}
                            <ChangeMode />
                            <Navbar.Toggle aria-controls="navbarSupportedContent">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </Navbar.Toggle>
                            <Navbar.Collapse id="navbarSupportedContent">
                                <Nav as="ul" className="ml-auto navbar-list align-items-center">

                                    <Dropdown as="li" className="nav-item nav-icon">
                                        <Dropdown.Toggle as={Button} href="#" variant="nav-item nav-icon pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            <img src={props?.user?.user?.user?.userModel?.image ? process.env.REACT_APP_BACKEND_URL + props?.user?.user?.user?.userModel?.image : noImage} className="img-fluid avatar-rounded" alt="user" />
                                            <span className="mb-0 ml-2 user-name">{props?.user?.user?.user?.userModel?.name}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as="ul" className="dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            {/* <Dropdown.Item as="li" className="d-flex svg-icon">
                                                <svg className="svg-icon mr-0 text-secondary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                <Link to="/user-profile">My Profile</Link>
                                            </Dropdown.Item> */}
                                            <Dropdown.Item as="li" className="d-flex svg-icon">
                                                {/* <svg className="svg-icon mr-0 text-secondary" id="h-02-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg> */}
                                                <svg className="svg-icon mr-0 text-secondary" id="h-02-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                </svg>
                                                <Link to="/account-settings">Account Settings</Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item as="li" className="d-flex svg-icon border-top">
                                                <svg className="svg-icon mr-0 text-secondary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                                </svg>
                                                <a href="#" onClick={handleLogout}>Logout</a>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </div>
            </div>
        </>
    )
}


const mapDispatchToProps = {
    logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStyle1);