import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Card, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from "xlsx";


const TopUpTransactions = ({ user }) => {
    const dataTableRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [transactionsSummery, setTransactionsSummery] = useState([]);
    const [reportDateRange, setReportDateRange] = useState([]);
    const [show, setShow] = useState(false);
    const [fullResponse, setFullResponse] = useState('');
    const [filterModalShow, setFilterModalShow] = useState(false);
    const [countries, setCountries] = useState([]);
    const [resellers, setResellers] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isUserAdmin, setIsUserAdmin] = useState(null);
    const [filedownloading, setFiledownloading] = useState(false);


    useEffect(() => {
        setIsUserAdmin(user.user.user.user.userModel.is_admin);
    }, [user?.user?.user?.user?.userModel])
    const [filterData, setFilterData] = useState({
        user_id: user?.user?.user?.user?.userModel?.id,
        id: '',
        transaction_id: '',
        reseller: '',
        vendor: '',
        operator: '',
        receivers_phone: '',
        country: '',
        status: '',
        start_date: '',
        end_date: '',
        productType: ''
    });

    useEffect(() => {
        getCountries();
        const token = user.user.user.token;
        const headers = { Authorization: `Bearer ${token}` }

        setIsLoading(true);

        axios.post(process.env.REACT_APP_API_URL + '/get-topup-transactions', filterData, { headers })
            .then((res) => {
                if (res?.data?.status) {
                    setTransactions(res?.data?.data);
                    setTransactionsSummery(res?.data?.transactionSummery);
                    setReportDateRange(res?.data?.dateRange);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err, "err");
            });
    }, []);

    useEffect(() => {
        // Initialize the DataTable when categories change
        if (!isLoading && transactions.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.status-button', function () {
            const transactionResponse = $(this).data('transaction-response');
            setFullResponse(transactionResponse);
            handleShow();
        });
    }, [transactions, isLoading]);

    const getCountries = () => {
        const token = user.user.user.token;
        const headers = {
            Authorization: `Bearer ${token}`,
        }
        axios.get(process.env.REACT_APP_API_URL + '/get-countries-for-operators-filter', { headers })
            .then((res) => {
                if (res.data.status) {
                    setCountries(res.data.data);
                    setResellers(res.data.resellers);
                }
            })
            .catch((err) => {
                console.log(err, "err");
            })
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleFilterModalClose = () => {
        setFilterModalShow(false);
        setFilterData({
            user_id: user?.user?.user?.user?.userModel?.id,
            id: '',
            transaction_id: '',
            reseller: '',
            vendor: '',
            operator: '',
            receivers_phone: '',
            country: '',
            status: '',
            start_date: '',
            end_date: '',
            productType: ''
        });
        setStartDate("");
        setEndDate("");
    }

    const onFilterChangeHandler = (e) => {
        console.log("test", e.target.value);
        if (e.target.name === 'id') {
            setFilterData(prevState => ({
                ...prevState,
                id: e.target.value
            }));
        } else if (e.target.name === 'transaction_id') {
            setFilterData(prevState => ({
                ...prevState,
                transaction_id: e.target.value
            }));
        } else if (e.target.name === 'reseller') {
            setFilterData(prevState => ({
                ...prevState,
                reseller: e.target.value
            }));
        } else if (e.target.name === 'vendor') {
            setFilterData(prevState => ({
                ...prevState,
                vendor: e.target.value
            }));
        } else if (e.target.name === 'operator') {
            setFilterData(prevState => ({
                ...prevState,
                operator: e.target.value
            }));
        } else if (e.target.name === 'receivers_phone') {
            setFilterData(prevState => ({
                ...prevState,
                receivers_phone: e.target.value
            }));
        } else if (e.target.name === 'country') {
            setFilterData(prevState => ({
                ...prevState,
                country: e.target.value
            }));
        } else if (e.target.name === 'status') {
            setFilterData(prevState => ({
                ...prevState,
                status: e.target.value
            }));
        } else if (e.target.name === 'start_date') {
            setFilterData(prevState => ({
                ...prevState,
                status: e.target.value
            }));
        }
        else if (e.target.name === 'end_date') {
            setFilterData(prevState => ({
                ...prevState,
                status: e.target.value
            }));
        }
        else if (e.target.name === 'productType') {
            setFilterData(prevState => ({
                ...prevState,
                productType: e.target.value
            }));
        }
    }
    const onDateChangeHandler = (date, type) => {
        const adjustedDate = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
        if (type === 'start') {
            setStartDate(adjustedDate);
            setFilterData(prevState => ({
                ...prevState,
                start_date: adjustedDate
            }));
        } else {
            setEndDate(adjustedDate);
            setFilterData(prevState => ({
                ...prevState,
                end_date: adjustedDate
            }));
        }
    };
    const onFilterSubmitHandler = () => {
        handleFilterModalClose();

        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
            $(dataTableRef.current).DataTable().destroy(); // Destroy existing DataTable
        }

        const token = user.user.user.token;
        const headers = { Authorization: `Bearer ${token}` }

        setIsLoading(true);

        axios.post(process.env.REACT_APP_API_URL + '/get-topup-transactions', filterData, { headers })
            .then((res) => {
                if (res?.data?.status) {
                    setTransactions(res?.data?.data);
                    setTransactionsSummery(res?.data?.transactionSummery);
                    setReportDateRange(res?.data?.dateRange);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err, "err");
            });
    }

    const openFilterModal = () => {
        setFilterModalShow(true);
    }
    const downloadCSV = () => {
        if (transactions.length === 0) return;
        setFiledownloading(true);

        setTimeout(() => {
            let data = [];

            // Define headers dynamically based on the user's role
            let headers = [
                "#", "Date", "Transaction Id",
                ...(isUserAdmin ? ["Reseller"] : []),
                ...(isUserAdmin ? ["Company"] : []),
                ...(isUserAdmin ? ["Vendor"] : []),
                "Country", "Operator", "Receiver's Phone",
                "TopUp Amount", "Receive Amount",
                "Reseller selling price", "Reseller Gain",
                ...(isUserAdmin ? ["Admin Margin"] : []),
                ...(isUserAdmin ? ["Admin Gain"] : []),
                ...(isUserAdmin ? ["Discount"] : []),
                ...(isUserAdmin ? ["Country Discount%"] : []),
                ...(isUserAdmin ? ["Operator Discount%"] : []),
                ...(isUserAdmin ? ["Vendor Commission %"] : []),
                ...(isUserAdmin ? ["Vendor Commission amount"] : []),
                ...(isUserAdmin ? ["Admin Total Gain"] : []),
                "Status"
            ];

            // Add headers to data
            data.push(headers);

            // Add transaction rows
            transactions.forEach(transaction => {
                let row = [
                    transaction.id,
                    transaction.created_at,
                    transaction.transaction_id ?? "N/A",
                    ...(isUserAdmin ? [transaction?.reseller, transaction?.company_name, transaction?.gateway] : []),
                    transaction.country,
                    transaction.operator,
                    transaction.full_number,
                    `€ ${Math.round(transaction.amount * 100) / 100}`,
                    `${transaction?.receiver_amount_currency} ${Math.round(transaction.receiver_amount * 100) / 100}`,
                    `€ ${Math.round(transaction.total_amount * 100) / 100}`,
                    `€ ${transaction?.reseller_gain ?? 0}`,
                    ...(isUserAdmin ? [
                        transaction?.admin_margin ? `${transaction.admin_margin}%` : "0%",
                        `€ ${transaction?.admin_gain ?? 0}`
                    ] : []),
                    ...(isUserAdmin ? [`€ ${transaction?.discount_amount ?? "0.00"}`] : []),
                    ...(isUserAdmin ? [`€ ${transaction?.country_discount_percentage ?? "0.00"}`] : []),
                    ...(isUserAdmin ? [`€ ${transaction?.operator_discount_percentage ?? "0.00"}`] : []),
                    ...(isUserAdmin ? [
                        transaction?.vendor_commission_percentage ? `${transaction.vendor_commission_percentage}%` : "0%",
                        `€ ${transaction?.vendor_commission_amount ?? 0}`
                    ] : []),
                    ...(isUserAdmin ? [`€ ${transaction?.admin_total_gain ?? "0.00"}`] : []),
                    transaction.status
                ];
                data.push(row);
            });

            // Calculate totals
            const successTransactions = transactions.filter(t => t.status === "success");

            const totals = successTransactions.reduce((acc, t) => ({
                topup: acc.topup + (Number(t.amount) || 0),
                receive: acc.receive + (Number(t.receiver_amount) || 0),
                adminGain: acc.adminGain + (Number(t.admin_gain) || 0),
                resellerGain: acc.resellerGain + (Number(t.reseller_gain) || 0),
                vendorCommissionPercent: acc.vendorCommissionPercent + (Number(t.vendor_commission_percentage) || 0),
                vendorCommissionAmount: acc.vendorCommissionAmount + (Number(t.vendor_commission_amount) || 0),
                discount: acc.discount + (Number(t.discount_amount) || 0),
                total: acc.total + (Number(t.total_amount) || 0),
                adminTotalGain: acc.adminTotalGain + (Number(t.admin_total_gain) || 0),
            }), {
                topup: 0, receive: 0, adminGain: 0, resellerGain: 0,
                vendorCommissionPercent: 0, vendorCommissionAmount: 0,
                discount: 0, total: 0, adminTotalGain: 0
            });

            // Add total row
            let totalRow = [
                "Total:", // First column
                ...(isUserAdmin ? ["-", "-", "-"] : []), // Reseller & Vendor columns (if admin)
                "-", "-", "-", "-", "-", // Country, Operator, Receiver's Phone
                `€ ${totals.topup.toFixed(2)}`, "-", `€ ${totals.total.toFixed(2)}`, // TopUp Amount, Receive Amount
                `€ ${totals.resellerGain.toFixed(2)}`, // Reseller Gain
                ...(isUserAdmin ? ["-", `€ ${totals.adminGain.toFixed(2)}`] : []), // Admin Margin, Admin Gain
                ...(isUserAdmin ? [`€ ${totals.discount.toFixed(2)}`] : []), // Discount (if admin)
                ...(isUserAdmin ? ["-", "-"] : []), // Country Discount%, Operator Discount%
                ...(isUserAdmin ? [`${totals.vendorCommissionPercent.toFixed(2)}%`, `€ ${totals.vendorCommissionAmount.toFixed(2)}`] : []), // Vendor Commission
                ...(isUserAdmin ? [`€ ${totals.adminTotalGain.toFixed(2)}`] : []), // Admin Total Gain
                "-", // Status
            ];

            data.push(totalRow);

            // Create worksheet
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

            // Save Excel file
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(blob, "report.xlsx");

            setFiledownloading(false);
        }, 500);
    };

    return (
        <section>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title as="h5">Full Response</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <pre>{JSON.stringify(fullResponse, null, 2)}</pre>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={filterModalShow}>
                <Modal.Header>
                    <Modal.Title as="h5">Filter Top up Transactions</Modal.Title>
                    <button className="badge bg-danger modal-close-button" onClick={handleFilterModalClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="transaction_id">Transaction Id</Form.Label>
                                    <Form.Control id="transaction_id" onChange={onFilterChangeHandler} name="transaction_id" placeholder='Enter Transaction Id' value={filterData.transaction_id} />
                                </Form.Group>
                            </Col>
                            {user?.user?.user?.user?.userModel?.is_admin ?
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="reseller">Reseller</Form.Label>
                                        <Form.Select id="reseller" className="form-control" name="reseller" onChange={onFilterChangeHandler} value={filterData.reseller}>
                                            <option value={''} disabled>Select Reseller</option>
                                            {resellers.length > 0 ? resellers.map((reseller, i) => {
                                                return (
                                                    <option value={reseller.name} key={i}>{reseller.name}</option>
                                                )
                                            }) : null}
                                        </Form.Select>
                                    </Form.Group>
                                </Col> : null}
                            {user?.user?.user?.user?.userModel?.is_admin ?
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="vendor">Vendor</Form.Label>
                                        <Form.Select id="vendor" className="form-control" name="vendor" onChange={onFilterChangeHandler} value={filterData.vendor}>
                                            <option value={''} disabled>Select Status</option>
                                            <option value={'RELOADLY'}>Reloadly</option>
                                            <option value={'DING_CONNECT'}>Ding Connect</option>
                                            <option value={'ZENDIT'}>Zendit</option>
                                            <option value={'PREPAY'}>Prepay</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col> : null}
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="operator">Operator</Form.Label>
                                    <Form.Control id="operator" onChange={onFilterChangeHandler} name="operator" placeholder='Enter Operator' value={filterData.operator} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="receivers_phone">Receiver Phone</Form.Label>
                                    <Form.Control id="receivers_phone" onChange={onFilterChangeHandler} name="receivers_phone" placeholder='Enter Receiver Phone' value={filterData.receivers_phone} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="country">Country</Form.Label>
                                    <Form.Select id="country" className="form-control" name="country" onChange={onFilterChangeHandler} value={filterData.country}>
                                        <option value={''} disabled>Select Country</option>
                                        {countries.length > 0 ? countries.map((country, i) => {
                                            return (
                                                <option value={country.country} key={i}>{country.country}</option>
                                            )
                                        }) : null}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="status">Start Date</Form.Label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => onDateChangeHandler(date, 'start')}
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="status">End Date</Form.Label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => onDateChangeHandler(date, 'end')}
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                    />

                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Form.Select id="status" className="form-control" name="status" onChange={onFilterChangeHandler} value={filterData.status}>
                                        <option value={''} disabled>Select Status</option>
                                        <option value={'success'}>Success</option>
                                        <option value={'failed'}>Failed</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="status">Product Type</Form.Label>
                                    <Form.Select id="productType" className="form-control" name="productType" onChange={onFilterChangeHandler} value={filterData.productType}>
                                        <option value={''} disabled>Select Type</option>
                                        <option value={'data'}>Data</option>
                                        <option value={'topup'}>Topup</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary loginBtn" onClick={onFilterSubmitHandler}>Submit</Button>
                </Modal.Footer>
            </Modal>
            <Container>
                {user?.user?.user?.user?.userModel?.is_admin ? <Row>
                    <Col lg="12" className="mb-4">
                        <h5>Presenting Data {reportDateRange}</h5>
                    </Col>
                    {/* <Col lg="12">
                        <Row>
                            <Col md="4">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="">
                                                <p className="mb-2 text-secondary">Transaction Count</p>
                                                {isLoading ? (
                                                    <div className="text-center">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                        <h5 className="mb-0 font-weight-bold">{transactionsSummery.transactionCount}</h5>

                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="">
                                                <p className="mb-2 text-secondary">Total Transaction Amount</p>
                                                {isLoading ? (
                                                    <div className="text-center">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                        <h5 className="mb-0 font-weight-bold">&euro;{transactionsSummery.totalAmount}</h5>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="">
                                                <p className="mb-2 text-secondary">Admin Gained Amount</p>
                                                {isLoading ? (
                                                    <div className="text-center">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                        <h5 className="mb-0 font-weight-bold">&euro;{transactionsSummery.adminGain}</h5>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="">
                                                <p className="mb-2 text-secondary">Total Discount</p>
                                                {isLoading ? (
                                                    <div className="text-center">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                        <h5 className="mb-0 font-weight-bold">&euro;{transactionsSummery.totalDiscount}</h5>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="">
                                                <p className="mb-2 text-secondary">Reseller Gained Amount</p>
                                                {isLoading ? (
                                                    <div className="text-center">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                        <h5 className="mb-0 font-weight-bold">&euro;{transactionsSummery.resellerAmountTotal}</h5>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col> */}
                </Row> : null}
                <Row>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">Top Up Transactions</h5>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Filter</Tooltip>}>
                                        <button className="btn btn-primary loginBtn" onClick={openFilterModal}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                                            </svg>
                                        </button>
                                    </OverlayTrigger>
                                </div>
                                <div className="table-responsive">
                                    <div className="d-flex justify-content-end mb-3">
                                        <button onClick={downloadCSV} className="btn btn-primary" disabled={filedownloading}>
                                            {filedownloading ? "Downloading..." : "Download Report"}
                                        </button>
                                    </div>
                                    {isLoading ? (
                                        <div className="text-center mb-5">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : transactions.length > 0 ? (
                                        <table ref={dataTableRef} className="table data-table mb-0">
                                            <thead className="table-color-heading text-left">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Transaction Id</th>
                                                    {isUserAdmin ? <th scope="col">Reseller</th> : null}
                                                    {isUserAdmin ? <th scope="col">Vendor</th> : null}
                                                    <th scope="col">Country</th>
                                                    <th scope="col">Operator</th>
                                                    <th scope="col">Receiver's Phone</th>
                                                    <th scope="col">TopUp Amount</th>
                                                    <th scope="col">Receive Amount</th>
                                                    <th scope="col">Reseller selling price</th>
                                                    <th scope="col">Reseller Gain</th>
                                                    {isUserAdmin ? <th scope="col">Admin Margin</th> : null}
                                                    {isUserAdmin ? <th scope="col">Admin Gain</th> : null}
                                                    {isUserAdmin ? (<th scope="col">Discount</th>) : ''}
                                                    {isUserAdmin ? (<th scope="col">Country Discount%</th>) : ''}
                                                    {isUserAdmin ? (<th scope="col">Operator Discount%</th>) : ''}
                                                    {isUserAdmin ? <th scope="col">Vendor Commission %</th> : null}
                                                    {isUserAdmin ? <th scope="col">Vendor Commission amount</th> : null}
                                                    {isUserAdmin ? <th scope="col">Admin Total Gain</th> : null}
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Order Receipt</th>
                                                    {isUserAdmin ? <th scope="col">Action</th> : null}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {transactions.map((transaction, index) => {
                                                    return (
                                                        <tr key={index} className="white-space-no-wrap">
                                                            <td>{transaction.id}</td>
                                                            <td>{transaction.created_at}</td>
                                                            <td>{transaction.transaction_id ? transaction.transaction_id : 'N/A'}</td>
                                                            {isUserAdmin ? <td>{transaction?.reseller} <br /> <i>{transaction?.company_name}</i></td> : null}
                                                            {isUserAdmin ? <td>{transaction?.gateway}</td> : null}
                                                            <td>{transaction.country}</td>
                                                            <td>{transaction.operator}</td>
                                                            <td>{transaction.full_number}</td>
                                                            <td>&euro; {Math.round(transaction.amount * 100) / 100}</td>
                                                            <td>{transaction?.receiver_amount_currency} {Math.round(transaction.receiver_amount * 100) / 100}</td>
                                                            <td>&euro; {Math.round(transaction.total_amount * 100) / 100}</td>
                                                            <td>&euro; {parseFloat(transaction?.reseller_gain ?? 0).toFixed(2)}</td>
                                                            {isUserAdmin ? <td>{transaction?.admin_margin ?? 0}%</td> : null}
                                                            {isUserAdmin ? <td>&euro; {transaction?.admin_gain ?? 0}</td> : null}
                                                            {isUserAdmin ? (<td>&euro; {transaction?.discount_amount ? transaction.discount_amount : "0.00%"}</td>) : ''}
                                                            {isUserAdmin ? (<td>&euro; {transaction?.country_discount_percentage ? transaction.country_discount_percentage + "%" : "0.00%"}</td>) : ''}
                                                            {isUserAdmin ? (<td>&euro; {transaction?.operator_discount_percentage ? transaction.operator_discount_percentage + "%" : "0.00%"}</td>) : ''}
                                                            {isUserAdmin ? <td>{transaction?.vendor_commission_percentage ?? 0}%</td> : null}
                                                            {isUserAdmin ? <td>&euro;{transaction?.vendor_commission_amount ?? 0}</td> : null}
                                                            {isUserAdmin ? (
                                                                <td>
                                                                    &euro;{(Number(transaction?.admin_total_gain) || 0).toFixed(2)}
                                                                </td>
                                                            ) : null}

                                                            <td>
                                                                <Badge pill className={transaction.status == 'success' ? "badge badge-success" : "badge badge-danger"}>
                                                                    {transaction.status}
                                                                </Badge>
                                                            </td>
                                                            <td>
                                                                {transaction.status == 'success' ?
                                                                    <Link to={`/order/${btoa(transaction.id)}`}>See Receipt</Link>
                                                                    : 'N/A'}
                                                            </td>
                                                            {user?.user?.user?.user?.userModel?.is_admin ? <td>
                                                                <div className="d-flex align-items-center">
                                                                    {/* Change Status */}
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>View Full Response</Tooltip>} >
                                                                        <div style={{ cursor: 'pointer' }} >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" data-transaction-response={transaction.response} className="w-6 h-6 status-button">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                                            </svg>

                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td> : null}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>

                                            <tfoot>
                                                {(() => {
                                                    const successTransactions = transactions.filter(t => t.status === 'success');

                                                    const totals = successTransactions.reduce((acc, t) => ({
                                                        topup: acc.topup + (Number(t.amount) || 0),
                                                        receive: acc.receive + (Number(t.receiver_amount) || 0),
                                                        adminGain: acc.adminGain + (Number(t.admin_gain) || 0),
                                                        resellerGain: acc.resellerGain + (Number(t.reseller_gain) || 0),
                                                        vendorCommissionPercent: acc.vendorCommissionPercent + (Number(t.vendor_commission_percentage) || 0),
                                                        vendorCommissionAmount: acc.vendorCommissionAmount + (Number(t.vendor_commission_amount) || 0),
                                                        discount: acc.discount + (Number(t.discount_amount) || 0),
                                                        total: acc.total + (Number(t.total_amount) || 0),
                                                        adminTotalGain: acc.adminTotalGain + (Number(t.admin_total_gain) || 0),
                                                    }), {
                                                        topup: 0, receive: 0, adminGain: 0, resellerGain: 0,
                                                        vendorCommissionPercent: 0, vendorCommissionAmount: 0,
                                                        discount: 0, total: 0, adminTotalGain: 0
                                                    });

                                                    // Safe number formatting function
                                                    const formatNumber = (num) => Number(num ?? 0).toFixed(2);

                                                    return (
                                                        <tr className="fw-bold bg-success">
                                                            {/* Adjust colSpan dynamically based on user role */}
                                                            <td colSpan={isUserAdmin ? 8 : 6}>Total:</td>
                                                            <td>&euro; {formatNumber(totals.topup)}</td>
                                                            <td>-</td>
                                                            <td>&euro; {formatNumber(totals.total)}</td>
                                                            <td>&euro; {formatNumber(totals.resellerGain)}</td>
                                                            {isUserAdmin && (
                                                                <>
                                                                    <td> - </td>
                                                                    <td>&euro; {formatNumber(totals.adminGain)}</td>
                                                                </>
                                                            )}
                                                            {isUserAdmin && (
                                                                <>
                                                                    <td>&euro; {formatNumber(totals.discount)}</td>
                                                                    <td> - </td>
                                                                    <td> - </td>
                                                                </>
                                                            )}
                                                            {isUserAdmin && (
                                                                <>
                                                                    <td>{formatNumber(totals.vendorCommissionPercent)}%</td>
                                                                    <td>&euro; {formatNumber(totals.vendorCommissionAmount)}</td>
                                                                    <td>&euro; {formatNumber(totals.adminTotalGain)}</td>
                                                                </>
                                                            )}

                                                            <td colSpan={isUserAdmin ? 3 : 1}></td>
                                                        </tr>
                                                    );
                                                })()}
                                            </tfoot>


                                        </table>
                                    ) : (
                                        <div className="text-center mb-5">
                                            <p>No transactions available.</p>
                                        </div>
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default TopUpTransactions;