import React, { useState } from "react";
import { useEffect } from 'react';
import { Button, Card, Col, Container, Form, FormGroup, Row, Spinner, Table } from 'react-bootstrap';
import noImage from '../../../assets/images/no-logo.jpg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { updateUserState } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { json } from "is";

const TopUpReloadly = ({ operators, number, numberOperatorId, numberOperatorName, onBack, numberOperatorDetails, userDetails, fullNumber, country, countryDiscount }) => {
    const dispatch = useDispatch();
    const [numbersOperator, setNumbersOperator] = useState('N/A');
    const [operatorDetailsLoading, setOperatorDetailsLoading] = useState(false);
    const [amountLimitError, setAmountLimitError] = useState("");
    const [destinationCurrencySymbol, setDestinationCurrencySymbol] = useState(false);
    const [minAmount, setMinAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [selectedOperator, setSelectedOperator] = useState({});
    const [receivedAmount, setReceivedAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [topUpAmount, setTopUpAmount] = useState(0);
    const [commission, setCommission] = useState(0);
    const [amountExcludeAdminCommission, setAmountExcludeAdminCommission] = useState(0);
    const [isReceiverReadOnly, setIsReceiverReadOnly] = useState(false);
    const [topLoading, setTopLoading] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(false);
    const [discountPercentage, setDiscountPercentage] = useState(false);
    const [adminMarginAmount, setAdminMarginAmount] = useState(0);
    const [topUpInputAmount, setTopUpInputAmount] = useState(0);


    useEffect(() => {
        if (operators?.length > 0 && numberOperatorId) {
            onOperatorClick(numberOperatorId.operatorId);
        }
    }, [operators, numberOperatorId]);

    useEffect(() => {
        setCommission(userDetails?.default_gain);
    }, [userDetails]);

    useEffect(() => {
        if (numberOperatorDetails) {
            var numberData = {};
            numberData.logo = numberOperatorDetails.logoUrls?.[0];
            numberData.name = numberOperatorDetails.name;
            numberData.operatorId = numberOperatorDetails.operatorId;
            numberData.discount = 0;
            setNumbersOperator(numberOperatorDetails);
        }
    }, [numberOperatorDetails]);

    if (!operators) return null; // Ensure we don't render if no operator data is available
    const goBack = (e) => {
        e.preventDefault();
        setSelectedOperator({});
        setReceivedAmount(0);
        setTotalAmount(0);
        setTopUpAmount(0);
        setTopUpInputAmount(0);
        setTopLoading(false);
        setCommission(0);
        setNumbersOperator('N/A');
        onBack();
        setOperatorDetailsLoading(false);
        setAmountExcludeAdminCommission(0);
    }
    const onOperatorClick = (id) => {
        setOperatorDetailsLoading(true);
        const selectOperator = operators.find(x => x.operator_id == id);
        axios.post(process.env.REACT_APP_API_URL + '/get-operator-details', selectOperator)
            .then((res) => {
                if (res?.status) {
                    setAmountLimitError("");
                    if (selectOperator?.response) {
                        var opdetails = JSON.parse(selectOperator.response);
                        setDestinationCurrencySymbol(opdetails.destinationCurrencySymbol);
                        setMinAmount(opdetails.minAmount);
                        setMaxAmount(opdetails.maxAmount);
                    }
                    var operatorDataResponse = JSON.parse(res?.data?.data.response);
                    var numberData = {};
                    numberData.logo = operatorDataResponse.logoUrls?.[0];
                    numberData.name = operatorDataResponse.name;
                    numberData.operatorId = operatorDataResponse.operatorId;
                    numberData.discount = operatorDataResponse.discount;
                    setNumbersOperator(numberData);
                    if (res?.data?.data.amount_type == "RANGE") {
                        setIsReceiverReadOnly(false);
                    } else {
                        setIsReceiverReadOnly(true);
                    }
                    setSelectedOperator(res?.data?.data);
                    setReceivedAmount(0);
                    setTotalAmount(0);
                    setTopUpAmount(0);
                    setTopUpInputAmount(0);
                    setOperatorDetailsLoading(false);
                } else {
                    setOperatorDetailsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setOperatorDetailsLoading(false);
            })
    }
    const getDiscountAmount = (discount, topupAmount) => {
        var discountAmount = 0;
        if (discount > 0) {
            discountAmount = parseInt(discount);
        } else {
            discountAmount = parseInt(countryDiscount);
        }
        setDiscountPercentage(discountAmount);
        var discountedAmount = (discountAmount / 100) * topupAmount;
        setDiscountAmount(discountedAmount);
        var updatedAmount = topupAmount + discountedAmount;
        return updatedAmount;
    }
    const getDiscountAmountForReceiverAmount = (discount, topupAmount) => {
        var discountAmount = 0;
        if (discount > 0) {
            discountAmount = parseInt(discount);
        } else {
            discountAmount = parseInt(countryDiscount);
        }
        setDiscountPercentage(discountAmount);
        var discountedAmount = (discountAmount / 100) * topupAmount;
        setDiscountAmount(discountedAmount);
        var updatedAmount = topupAmount - discountedAmount;
        return updatedAmount;
    }
    const calculateAdminMarginAmount = (orginalAmount) => {
        if (selectedOperator?.margin > 0) {
            var adminMargin = ((selectedOperator?.margin / 100) * orginalAmount);
            setAdminMarginAmount(adminMargin);
        }
    }
    const amountChangeHandler = (e, getAmount = null, sku = null, margin = null, originalAmount = 0, receiveValue = 0, discount = 0) => {

        if (getAmount !== null && getAmount !== '') {
            calculateAdminMarginAmount(originalAmount);
            const selectedTopUpAmount = parseFloat(getAmount);
            const topAmount = getDiscountAmount(discount, originalAmount);
            setIsReceiverReadOnly(true);
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                const amountWithCommission = parseFloat(selectedTopUpAmount) * (parseFloat(commission) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(selectedTopUpAmount);
                var amountToBeReceived = calculateAmountRecievedAsPerVendor(topAmount, originalAmount, userDetails?.gainType, receiveValue, sku);
                setTotalAmount(amountTotal);
                setAmountExcludeAdminCommission(topAmount);

                setTopUpAmount(validateAmountField(selectedTopUpAmount));
                setTopUpInputAmount(validateAmountField(selectedTopUpAmount));
                setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
            } else {

                var amountToBeReceived = calculateAmountRecievedAsPerVendor(topAmount, originalAmount, userDetails?.gainType, receiveValue, sku);
                const amountTotal = parseFloat(commission) + parseFloat(selectedTopUpAmount);
                setTotalAmount(amountTotal);
                setAmountExcludeAdminCommission(topAmount);
                setTopUpAmount(validateAmountField(selectedTopUpAmount));
                setTopUpInputAmount(validateAmountField(selectedTopUpAmount));
                setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
            }
        } else {
            if (e.target.value) {
                const topAmount = parseFloat(e.target.value);
                calculateAdminMarginAmount(topAmount);
                const discount = parseFloat(parseFloat(e.target.dataset.discount).toFixed(2));
                const selectedTopUpAmount = getDiscountAmount(discount, topAmount);
                setIsReceiverReadOnly(false);
                if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {

                    // const topAmount = parseFloat(e.target.value);
                    if (selectedOperator?.margin == 0) {

                        if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            setAmountLimitError("");
                        }
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(selectedTopUpAmount, originalAmount, userDetails?.gainType, receiveValue);
                        const commissionRate = (parseFloat(commission) / 100) * parseFloat(topAmount);
                        const amountTotal = parseFloat(topAmount) + commissionRate;
                        setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(selectedTopUpAmount.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));
                        setTopUpInputAmount(validateAmountField(topAmount));

                    } else {

                        const minAmount = parseFloat(parseFloat(e.target.dataset.minamount).toFixed(2));
                        const maxAmount = parseFloat(parseFloat(e.target.dataset.maxamount).toFixed(2));

                        let adminMargin = 0;
                        setAmountLimitError("");
                        if (minAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminminmargin);
                        } else if (maxAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminmaxmargin);
                        } else if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            adminMargin = ((selectedOperator?.margin / 100) * topAmount);
                        }
                        
                        const amountAfterAdminMargin = topAmount - adminMargin;
                        const amountAfterDiscountAmount = getDiscountAmount(discount, amountAfterAdminMargin);
                        const commissionRate = (parseFloat(commission) / 100) * parseFloat(topAmount);
                        const amountTotal = parseFloat(topAmount) + commissionRate;
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(topAmount, originalAmount, userDetails?.gainType, receiveValue);
                        setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(amountAfterDiscountAmount.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));
                        setTopUpInputAmount(validateAmountField(topAmount));
                    }
                }
                else {

                    if (selectedOperator?.margin == 0) {
                        const amountTotal = parseFloat(commission) + parseFloat(topAmount);
                        setTotalAmount(amountTotal);
                        if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            setAmountLimitError("");
                            var amountToBeReceived = calculateAmountRecievedAsPerVendor(selectedTopUpAmount, selectedTopUpAmount, userDetails?.gainType);
                            setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        }
                        setAmountExcludeAdminCommission(selectedTopUpAmount.toFixed(2));
                        setTopUpAmount(validateAmountField(topAmount));
                        setTopUpInputAmount(validateAmountField(topAmount));

                    } else {

                        const minAmount = parseFloat(parseFloat(e.target.dataset.minamount).toFixed(2));
                        const maxAmount = parseFloat(parseFloat(e.target.dataset.maxamount).toFixed(2));

                        let adminMargin = 0;
                        if (minAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminminmargin);
                            setAmountLimitError("");
                        } else if (maxAmount === topAmount) {
                            adminMargin = parseFloat(e.target.dataset.adminmaxmargin);
                            setAmountLimitError("");
                        } else if (topAmount < minAmount || topAmount > maxAmount) {
                            setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
                        } else {
                            adminMargin = ((selectedOperator?.margin / 100) * topAmount);
                            setAmountLimitError("");
                        }
                        const amountAfterAdminMargin = topAmount - adminMargin;
                        const amountAfterDiscountAmount = getDiscountAmount(discount, amountAfterAdminMargin);
                        const amountTotal = parseFloat(commission) + parseFloat(topAmount);
                        var amountToBeReceived = calculateAmountRecievedAsPerVendor(amountAfterDiscountAmount, amountAfterDiscountAmount, userDetails?.gainType);
                        if (amountToBeReceived) {
                            setReceivedAmount(validateAmountField(amountToBeReceived.toFixed(2)));
                        }
                        setTotalAmount(amountTotal);
                        setAmountExcludeAdminCommission(amountAfterDiscountAmount.toFixed(2));
                        setTopUpInputAmount(validateAmountField(topAmount));
                        setTopUpAmount(validateAmountField(topAmount));
                    }
                }
            } else {
                setReceivedAmount(0);
                setTotalAmount(0);
                setTopUpAmount(0);
                setTopUpInputAmount(0);
            }
        }
    }

    const onCommissionChangeHandler = (e) => {
        if (topUpInputAmount !== '' && topUpInputAmount !== null) {
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {
                const amountWithCommission = parseFloat(topUpInputAmount) * (parseFloat(e.target.value) / 100);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpInputAmount);
                setCommission(validateAmountField(e.target.value));
                setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
            } else {
                const amountTotal = parseFloat(e.target.value) + parseFloat(topUpInputAmount);
                setCommission(validateAmountField(e.target.value));
                setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
            }
        } else {
            setCommission(validateAmountField(e.target.value));
        }
    }
    const onReceivedAmountChangeHandler = (e) => {


        if (e.target.value) {
            const expectedChargeAmount = e.target.value;
            var topUpAmountBeforAdminMargin = parseFloat(expectedChargeAmount) / parseFloat(selectedOperator?.fx_rates);
            calculateAdminMarginAmount(topUpAmountBeforAdminMargin);
            var adminMargin = parseFloat(e.target.dataset.margin);
            var topUpAmount=topUpAmountBeforAdminMargin;
            if (adminMargin > 0) {
                topUpAmount = (topUpAmountBeforAdminMargin + ((adminMargin / 100) * topUpAmount));
            }
            const discountedTopUpAmount = getDiscountAmountForReceiverAmount(parseFloat(e.target.dataset.discount), topUpAmount);
            setAmountLimitError("");
            if (discountedTopUpAmount < minAmount || discountedTopUpAmount > maxAmount) {
                setAmountLimitError("Amount cannot exceed the minimum or maximum allowed value.");
            }
            if (userDetails?.gainType == 1 || userDetails?.gainType == '1') {

                const amountWithCommission = parseFloat((parseFloat(commission) / 100) * topUpAmount).toFixed(2);
                const amountTotal = parseFloat(amountWithCommission) + parseFloat(topUpAmount);
                setReceivedAmount(expectedChargeAmount);
                setTopUpAmount(validateAmountField(discountedTopUpAmount.toFixed(2)));
                setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
                setAmountExcludeAdminCommission(topUpAmountBeforAdminMargin);
                setTopUpInputAmount(validateAmountField(discountedTopUpAmount.toFixed(2)));
            } else {
                const amountTotal = parseFloat(commission) + parseFloat(topUpAmount);
                setReceivedAmount(expectedChargeAmount);
                setTopUpAmount(validateAmountField(discountedTopUpAmount.toFixed(2)));
                setTopUpInputAmount(validateAmountField(discountedTopUpAmount));
                setTotalAmount(validateAmountField(amountTotal.toFixed(2)));
                setAmountExcludeAdminCommission(topUpAmountBeforAdminMargin);
            }
        } else {
            setReceivedAmount('');
            setTotalAmount('');
            setTopUpAmount('');
            setAmountLimitError("");
        }
    }
    const checkValidation = () => {
        if (JSON.parse(selectedOperator?.response)?.fixedAmounts?.length > 0) {

            if (JSON.parse(selectedOperator?.response)?.fixedAmounts?.includes(parseFloat(amountExcludeAdminCommission))) {
                if (parseFloat(topUpAmount) <= userDetails?.balance) {
                    return true;
                } else {
                    Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                    return false;
                }
            } else {
                var requiredAmounts = JSON.parse(selectedOperator?.response)?.fixedAmounts?.map(amount => ' ' + (amount + (amount * (selectedOperator?.margin / 100))).toFixed(2))
                Swal.fire('Ooops!', 'Please enter a amount from these ' + requiredAmounts + '.', 'error');
                return false;
            }


        } else if (JSON.parse(selectedOperator?.response)?.maxAmount !== null && JSON.parse(selectedOperator?.response)?.minAmount !== null) {

            if (parseFloat(topUpAmount) > (JSON.parse(selectedOperator?.response)?.maxAmount + (JSON.parse(selectedOperator?.response)?.maxAmount * (selectedOperator?.margin / 100)))) {
                Swal.fire('Ooops!', 'Please enter amount less than or equal to ' + (JSON.parse(selectedOperator?.response)?.maxAmount + (JSON.parse(selectedOperator?.response)?.maxAmount * (selectedOperator?.margin / 100))) + '.', 'error');
                return false;
            } else if (parseFloat(topUpAmount) < (JSON.parse(selectedOperator?.response)?.minAmount + (JSON.parse(selectedOperator?.response)?.minAmount * (selectedOperator?.margin / 100))).toFixed(2)) {
                Swal.fire('Ooops!', 'Please enter amount greater than or equal to ' + (JSON.parse(selectedOperator?.response)?.minAmount + (JSON.parse(selectedOperator?.response)?.minAmount * (selectedOperator?.margin / 100))).toFixed(2) + '.', 'error');
                return false;
            } else {
                if (parseFloat(topUpAmount) <= userDetails?.balance) {
                    return true;
                } else {
                    Swal.fire('Ooops!', 'You do not have enough balance in your account.', 'error');
                    return false;
                }
            }
        } else if (amountLimitError != "") {
            Swal.fire('Ooops!', 'Amount cannot exceed the minimum or maximum allowed value', 'error');
            return false;
        }

        return true;
    }
    const validateAmountField = (value) => {
        value = Number(value);
        if (isNaN(value) || value < 0) {
            value = 0;
        }
        return value;
    }
    const onTopUpSubmitHandler = () => {
        const validate = checkValidation();

        if (validate) {
            var vendorCommission = JSON.parse(selectedOperator?.response)?.commission;
            setAmountLimitError("");
            const data = {
                id: selectedOperator?.id,
                gateway_id: selectedOperator?.vendor_id,
                user_id: userDetails?.user_id,
                phone: number,
                fullNumber: fullNumber,
                totalAmount: parseFloat(totalAmount).toFixed(2),
                countryCode: country,
                receivedAmount: receivedAmount,
                amountWithAdminCommission: topUpAmount,
                amount: amountExcludeAdminCommission,
                discountAmount: discountAmount,
                receiverAmountCurrency: destinationCurrencySymbol,
                adminMargin: selectedOperator?.margin,
                adminMarginAmount: adminMarginAmount,
                resellerGain: (totalAmount - topUpAmount),
                vendorCommission: 0,
                vendorCommissionPercentage: vendorCommission
            }
            setTopLoading(true);
            axios.post(process.env.REACT_APP_API_URL + '/get-topup', data)
                .then((res) => {
                    if (res?.data?.status == 'success') {
                        setTopLoading(false);
                        Swal.fire('Done!', res.data.message, 'success').then(() => {
                            dispatch(updateUserState(res?.data?.data));
                            setReceivedAmount(0);
                            setTotalAmount(0);
                            setTopUpAmount(0);
                            setSelectedOperator(selectedOperator);
                        });
                    } else {
                        setTopLoading(false);
                        Swal.fire('Oops!', res.data.message, 'error');
                    }
                })
                .catch((err) => {
                    console.log(err, "err");
                    setTopLoading(false);
                    Swal.fire('Oops!', err.response.data.message, 'error');
                });
        }
    }
    const calculateAmountRecievedAsPerVendor = (getAmount, originalAmount = 0, gainType, receiveValue = 0, sku = 0) => {

        var amountToBeReceived = 0;
        if (selectedOperator?.amount_type == "FIXED") {
            amountToBeReceived = getAmount * parseFloat(selectedOperator?.fx_rates);
        } else {
            amountToBeReceived = getAmount * parseFloat(selectedOperator?.fx_rates);
        }
        return amountToBeReceived;
    }
    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Top Up Details
                        </Card.Title>
                        <Button type="button" className="float-right mt-3" variant="btn btn-primary loginBtn" onClick={(e) => goBack(e)}>Back</Button>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6} className="d-flex align-items-center">
                                {numbersOperator?.logo && (
                                    <img
                                        src={numbersOperator.logo}
                                        alt="Operator Logo"
                                        className="img-fluid mr-2"
                                        style={{ height: "80px" }}
                                    />
                                )}
                                <div>
                                    <h4 className="mb-1">{numbersOperator.name}</h4>
                                    <h4 className="mb-0">{number}</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col md={3}>
                                <label>Choose Operator: </label>
                            </Col>
                            <Col md={9}>
                                <div className='operators-main-box'>
                                    {operators?.length > 0 ? operators?.map((operator, index) => {
                                        return (
                                            <div className={numbersOperator.operatorId == operator?.operator_id ? 'operator-box active' : 'operator-box'} key={index} onClick={() => onOperatorClick(operator?.operator_id)}>{operator?.name}</div>
                                        )
                                    }) : null}
                                </div>
                            </Col>
                        </Row>


                        {operatorDetailsLoading ?
                            <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                            :
                            Object.keys(selectedOperator).length > 0 ?
                                (selectedOperator?.response && JSON.parse(selectedOperator?.response).fixedAmounts?.length > 0 ?
                                    <Row className='mt-3'>
                                        <Col md={3}>
                                            <label>Choose Amount: </label>
                                        </Col>
                                        <Col md={9}>
                                            <div className='amount-main-box'>
                                                {JSON.parse(selectedOperator?.response)?.fixedAmounts?.map((amount, i) => {
                                                    let exactAmount;
                                                    selectedOperator?.margin == 0 ?
                                                        exactAmount = parseFloat(amount)
                                                        :
                                                        exactAmount = parseFloat(amount) + (parseFloat(amount) * (parseFloat(selectedOperator?.margin) / 100))
                                                    return (
                                                        <div key={i} onClick={(e) => amountChangeHandler(e, exactAmount.toFixed(2), null, null, amount, selectedOperator?.response?.discount)} className={topUpAmount == exactAmount.toFixed(2) ? 'amount-box active' : 'amount-box'}>{exactAmount.toFixed(2)} EUR</div>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    (JSON.parse(selectedOperator?.response).minAmount !== null && JSON.parse(selectedOperator?.response).maxAmount !== null ?
                                        <Row className='mt-3 align-items-center'>
                                            <Col md={3}>
                                                {selectedOperator?.margin == 0 ?
                                                    <label>Amount &euro; ({parseFloat(JSON.parse(selectedOperator?.response)?.minAmount).toFixed(2)}/{parseFloat(JSON.parse(selectedOperator?.response)?.maxAmount).toFixed(2)}) </label>
                                                    :
                                                    <label>Amount &euro; ({(parseFloat(JSON.parse(selectedOperator?.response)?.minAmount) + (parseFloat(JSON.parse(selectedOperator?.response)?.minAmount) * (parseFloat(selectedOperator?.margin) / 100))).toFixed(2)}/{(JSON.parse(selectedOperator?.response)?.maxAmount + (JSON.parse(selectedOperator?.response)?.maxAmount * (JSON.parse(selectedOperator?.margin) / 100))).toFixed(2)}) </label>
                                                }
                                            </Col>
                                            <Col md={9}>
                                                {selectedOperator?.margin == 0 ?
                                                    <input className='form-control' type='number' data-discount={selectedOperator?.discount} onChange={(e) => amountChangeHandler(e)} value={topUpInputAmount} />
                                                    :
                                                    <input className='form-control' type='number' data-discount={selectedOperator?.discount} data-minamount={(parseFloat(JSON.parse(selectedOperator?.response)?.minAmount) + (parseFloat(JSON.parse(selectedOperator?.response)?.minAmount) * (parseFloat(selectedOperator?.margin) / 100)))} data-maxamount={(JSON.parse(selectedOperator?.response)?.maxAmount + (JSON.parse(selectedOperator?.response)?.maxAmount * (JSON.parse(selectedOperator?.margin) / 100)))} data-adminminmargin={parseFloat(JSON.parse(selectedOperator?.response)?.minAmount) * (parseFloat(selectedOperator?.margin) / 100)} data-adminmaxmargin={JSON.parse(selectedOperator?.response)?.maxAmount * (JSON.parse(selectedOperator?.margin) / 100)} onChange={(e) => amountChangeHandler(e)} value={topUpInputAmount} />
                                                }
                                                <label style={{ color: "red" }}>
                                                    {amountLimitError}
                                                </label>
                                            </Col>
                                        </Row> : null
                                    ))
                                : null}
                        {userDetails?.gainType == 1 || userDetails?.gainType == '1' ?
                            <Row className='mt-3 align-items-center'>
                                <Col md={3}>
                                    <label>Commission (in Percentage %)</label>
                                </Col>
                                <Col md={9}>
                                    <input className='form-control' value={parseFloat(commission)} onChange={onCommissionChangeHandler} />
                                </Col>
                            </Row>
                            :
                            <Row className='mt-3 align-items-center'>
                                <Col md={3}>
                                    <label>Commission (in Euro &euro;)</label>
                                </Col>
                                <Col md={9}>
                                    <input className='form-control' value={parseFloat(parseFloat(commission).toFixed(2))} onChange={onCommissionChangeHandler} />
                                </Col>
                            </Row>
                        }
                        <Row className='mt-3 align-items-center'>
                            <Col md={3}>
                                <label>Total Amount (in Euro &euro;)</label>
                            </Col>
                            <Col md={9}>
                                <input className='form-control' value={totalAmount == 0 ? totalAmount : parseFloat(totalAmount).toFixed(2)} readOnly />
                            </Col>
                        </Row>
                        <Row className='mt-3 align-items-center'>
                            <Col md={3}>
                                <label>Receiver Amount (in {destinationCurrencySymbol})</label>
                            </Col>
                            <Col md={9}>
                                <input
                                    className="form-control"
                                    value={receivedAmount}
                                    onChange={(e) => onReceivedAmountChangeHandler(e)}
                                    placeholder="Enter amount"
                                    readOnly={isReceiverReadOnly}
                                    data-discount={selectedOperator?.discount}
                                    data-margin={selectedOperator?.margin}
                                />
                            </Col>

                        </Row>
                        <Button type="button" className="float-right mt-3" variant="btn btn-primary loginBtn" onClick={onTopUpSubmitHandler}>
                            {topLoading ? (
                                <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}Loading...</>
                            ) : ('Submit')}
                        </Button>



                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default TopUpReloadly;
