import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import Chart from "react-apexcharts";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'react-circular-progressbar/dist/styles.css';
import { Card, Container, Row, Col, Nav, Tab, Spinner, Form, Button } from 'react-bootstrap';
import './dashboard.css';

const Dashbord = ({ user }) => {

    const [salesData, setSalesData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [topSellingProductsData, setTopSellingProductsData] = useState([]);
    const [topSellingCategoriesData, setTopSellingCategoriesData] = useState([]);
    const [topSellingCategoriesLabels, setTopSellingCategoriesLabels] = useState([]);
    const [mostRevenueGeneratedProducts, setMostRevenueGeneratedProducts] = useState([]);
    const [newOrderData, setNewOrderData] = useState([]);
    const [saleGraphData, setSaleGraphData] = useState([]);
    const [saleGraphDataYaxis, setSaleGraphDataYaxis] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [showDateInputs, setShowDateInputs] = useState(false);
    const [companyCurrencySymbol, setCompanyCurrencySymbol] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [fetchingData, setFetchingData] = useState(false);
    const [customSaleGraph, setCustomSaleGraph] = useState(false);
    const [transactionsSummery, setTransactionsSummery] = useState([]);
    const [mostTopupCountries, setMostTopupCountries] = useState([]);
    const [mostTopupVendors, setMostTopupVendors] = useState([]);
    const [rechargeRequests, setRechargeRequests] = useState([]);
    const [successTransactionCount, setSuccessTransactionCount] = useState(0);
    const [failedTransactionCount, setFailedTransactionCount] = useState(0);
    const [totalAdminGain, setTotalAdminGain] = useState(0);
    const [topUsersTransactionByAmount, setTopUsersTransactionByAmount] = useState([]);
    const [operatorsWithDiscount, setOperatorsWithDiscount] = useState([]);
    const [countriesWithDiscount, setCountriesWithDiscount] = useState([]);

    useEffect(() => {
        fetchDashboardData();
        document.title = 'Dashboard | 2G Connect';
    }, []);

    const fetchDashboardData = () => {
        setFetchingData(true);
        setIsLoading(true);
        const token = user.user.user.token
        const filters = {
            startDate: startDate,
            endDate: endDate,
            userId: user?.user?.user?.user?.userModel?.is_admin ? '' : user?.user?.user?.user?.userModel?.id
        }
        axios
            .post(process.env.REACT_APP_API_URL + '/dashboard', filters, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {

                setTransactionsSummery(response?.data?.transactionSummery);
                setMostTopupCountries(response?.data?.mostTopupCountries);
                setMostTopupVendors(response?.data?.mostTopupVendors);
                setRechargeRequests(response?.data?.rechargeRequest);
                setSuccessTransactionCount(response?.data?.successTransactionCount);
                setFailedTransactionCount(response?.data?.failedTransactionCount);
                setTotalAdminGain(response?.data?.totalAdminGain);
                setTopUsersTransactionByAmount(response?.data?.topUser);
                setOperatorsWithDiscount(response?.data?.operatorsWithDiscount);
                setCountriesWithDiscount(response?.data?.countriesWithDiscount);

                const fetchedSales = response.data.sales;
                const fetchedProducts = response.data.products;
                const fetchedCustomers = response.data.customers;
                const fetchedTopSellingProducts = response.data.topSellingProducts;
                const fetchedMostRevenueGeneratedProducts = response.data.mostRevenueGeneratedProducts;
                const fetchedNewOrderData = response.data.newOrders;
                const fetchedTopSellingCategories = response.data.topSalesCategories;
                const fetchedSaleGraphData = response.data.salesGraph;
                const fetchedCompanyCurrencySymbol = response.data.companyCurrencyDetails.symbol;
                const yAxis = fetchedSaleGraphData.salesData.map(value => parseFloat(value));
                setSaleGraphDataYaxis(yAxis);

                const numericData = fetchedTopSellingCategories.data.map(value => parseFloat(value));
                setTopSellingCategoriesData(numericData);
                const cleanedLabels = fetchedTopSellingCategories.labels.map(label => label.trim());
                setTopSellingCategoriesLabels(cleanedLabels);
                setSalesData(fetchedSales);
                setProductData(fetchedProducts);
                setCustomerData(fetchedCustomers);
                setTopSellingProductsData(fetchedTopSellingProducts);
                setMostRevenueGeneratedProducts(fetchedMostRevenueGeneratedProducts);
                setNewOrderData(fetchedNewOrderData);
                setSaleGraphData(fetchedSaleGraphData);
                setCompanyCurrencySymbol(fetchedCompanyCurrencySymbol);
                setIsLoading(false);
                setFetchingData(false);
            })
            .catch((error) => {
                setFetchingData(false);
                console.error('Error fetching customers:', error);
                setIsLoading(false);
            });
    };

    const predefinedRanges = [
        {
            label: 'Last 7 Days',
            value: {
                startDate: moment().subtract(6, 'days').toDate(),
                endDate: new Date(),
            },
        },
        {
            label: 'Last 30 Days',
            value: {
                startDate: moment().subtract(30, 'days').toDate(),
                endDate: new Date(),
            },
        },
        {
            label: 'Last 3 Months',
            value: {
                startDate: moment().subtract(3, 'months').toDate(),
                endDate: new Date(),
            },
        },
        {
            label: 'Last 9 Months',
            value: {
                startDate: moment().subtract(9, 'months').toDate(),
                endDate: new Date(),
            },
        },
    ];

    const handleDateRangeChange = (range) => {
        if (range !== "range" && range !== "") {
            const parsedData = JSON.parse(range);
            setStartDate(parsedData.startDate);
            setEndDate(parsedData.endDate);
            setSelectedDateRange(parsedData.value); // Update the selected date range
            setSelectedDateRange(null); // Update the selected date range
            setShowDateInputs(false);
            try {
                // Attempt to parse the selected date range as JSON
                const parsedRange = parsedData.value;
                // Handle the parsed range (e.g., do something with it)
                // console.log(range);
            } catch (error) {
                // Handle any errors that occur during parsing
                console.error('Error parsing date range:', error);
            }
        } else if (range === "") {
            setStartDate("");
            setEndDate("");
            setSelectedDateRange(null); // Update the selected date range
            setShowDateInputs(false);
        } else {
            const currentDate = new Date();
            setStartDate(currentDate);
            setEndDate(currentDate);
            setSelectedDateRange(null); // Update the selected date range
            setShowDateInputs(true);
        }

    };

    const chart1 = {
        options: {

            colors: ['#1f1f7a', '#2e2eb8'],
            chart: {
                fontFamily: 'DM Sans',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "vertical",
                    shadeIntensity: 0.5,
                    // gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                    inverseColors: false,
                    opacityFrom: .8,
                    opacityTo: .2,
                    stops: [0, 50, 100],
                    colorStops: []
                }
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            yaxis: {
                labels: {
                    offsetY: 0,
                    minWidth: 40,
                    maxWidth: 40
                },
            },
            xaxis: {
                labels: {
                    offsetY: 0,
                    minWidth: 20,
                    maxWidth: 20,
                },
                categories: saleGraphData.labels
            },
            tooltip: {
                x: {
                    format: companyCurrencySymbol,
                },
                y: {
                    formatter: function (value) {
                        // Customize the tooltip format here, appending the currency icon
                        return companyCurrencySymbol + value.toFixed(2); // Assuming you want to display values with two decimal places
                    },
                },
            },
        },
        series: [{
            name: 'Sales',
            data: saleGraphDataYaxis
        }]
    }

    const chart3 = {
        options: {

            chart: {
                height: 330,
                type: 'donut',

            },

            labels: topSellingCategoriesLabels,
            colors: ['#ffbb33', '#04237D', '#e60000', '#8080ff'],
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                    donut: {
                        size: '80%',
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                formatter: function (val) {// Replace with your desired currency symbol
                                    const totalValue = topSellingCategoriesData.reduce((acc, val) => acc + val, 0);
                                    return companyCurrencySymbol + totalValue.toFixed(2); // Format as needed
                                },
                                color: '#BCC1C8',
                                fontSize: '18px',
                                fontFamily: 'DM Sans',
                                fontWeight: 600,
                            },
                            value: {
                                show: true,
                                formatter: function (val) {// Replace with your desired currency symbol
                                    return companyCurrencySymbol + val;
                                },
                                fontSize: '25px',
                                fontFamily: 'DM Sans',
                                fontWeight: 700,
                                color: '#8F9FBC',
                            },
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false,

            },
            stroke: {
                lineCap: 'round'
            },
            grid: {
                padding: {

                    bottom: 0,
                }
            },
            legend: {
                position: 'bottom',
                offsetY: 8,
                show: true,
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: function (val) {
                        return companyCurrencySymbol + val;
                    },
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 268
                    }
                }
            }]
        },
        series: topSellingCategoriesData
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#f39c12'; // Yellow
            case 'processing':
                return '#3498db'; // Blue
            case 'completed':
                return '#3cb72c'; // Green
            case 'canceled':
                return '#e74c3c'; // Red
            default:
                return '#000000'; // Black (Fallback color)
        }
    };

    const handleDateFormat = (date) => {
        const dateTimeString = date;
        const dateTime = new Date(dateTimeString);

        // Convert to a human-readable format
        const formattedDate = dateTime.toLocaleDateString(); // e.g. "8/26/2023"
        const formattedTime = dateTime.toLocaleTimeString(); // e.g. "12:00:00 AM"

        // Combine date and time
        const formattedDateTime = `${formattedDate} ${formattedTime}`;
        return formattedDateTime;
    }

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <Container fluid>
            <Row>
                <Col md="12" className="mb-4 mt-1">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <h4 className="font-weight-bold">Overview</h4>
                        <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
                            <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
                                <div className="date-icon-set">
                                    {showDateInputs && (
                                        <>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                maxDate={endDate}
                                                placeholderText="From Date"
                                                isClearable
                                                className="custom-datepicker" // Add custom class for styling
                                            />
                                            <span className="search-link">
                                                {/* Render calendar icon */}
                                                <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                </svg>
                                            </span>
                                        </>
                                    )}

                                </div>
                                {showDateInputs && (
                                    <span className="flex-grow-0">
                                        <span className="btn">To</span>
                                    </span>
                                )}
                                <div className="date-icon-set mr-2">
                                    {showDateInputs && (
                                        <>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                placeholderText="To Date"
                                                isClearable
                                                className="custom-datepicker"
                                            />
                                            <span className="search-link">
                                                {/* Render calendar icon */}
                                                <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                </svg>
                                            </span>
                                        </>
                                    )}

                                </div>
                                <div className="date-icon-set custom-select-container-dashboard">
                                    <select onChange={(e) => handleDateRangeChange(e.target.value)}
                                        className="custom-select"
                                    >
                                        <option value="">All Time</option>
                                        <option value="range">Custom Range</option>
                                        {predefinedRanges.map((range) => (
                                            <option key={range.label} value={JSON.stringify(range.value)}>
                                                {range.label}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className="date-icon-set">
                                    <button
                                        onClick={fetchDashboardData} // Add an onClick event handler to trigger data fetching
                                        className="btn btn-primary ml-2"
                                        disabled={fetchingData} // Disable the button while fetching data
                                    >
                                        {fetchingData ? 'Fetching Data...' : 'Fetch Data'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg="8" md="12">
                    <Row>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Transaction Count</p>
                                            {isLoading ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                    <h5 className="mb-0 font-weight-bold">{transactionsSummery.transactionCount}</h5>

                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Total Transaction Amount</p>
                                            {isLoading ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                    <h5 className="mb-0 font-weight-bold">&euro;{transactionsSummery.totalAmount}</h5>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        {user?.user?.user?.user?.userModel?.is_admin ? <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Admin Gained Amount</p>
                                            {isLoading ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                    <h5 className="mb-0 font-weight-bold">&euro;{totalAdminGain}</h5>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col> : null}
                        {user?.user?.user?.user?.userModel?.is_admin ?
                            <Col md="4">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="">
                                                <p className="mb-2 text-secondary">Total Discount</p>
                                                {isLoading ? (
                                                    <div className="text-center">
                                                        <Spinner animation="border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                        <h5 className="mb-0 font-weight-bold">&euro;{transactionsSummery.totalDiscount}</h5>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            : null}
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Reseller Gained Amount</p>
                                            {isLoading ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                    <h5 className="mb-0 font-weight-bold">&euro;{transactionsSummery.resellerAmountTotal}</h5>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Failed Transactions Count</p>
                                            {isLoading ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                    <h5 className="mb-0 font-weight-bold">{failedTransactionCount}</h5>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <p className="mb-2 text-secondary">Success Transactions Count</p>
                                            {isLoading ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <div className="d-flex flex-wrap justify-content-start align-items-center">
                                                    <h5 className="mb-0 font-weight-bold">{successTransactionCount}</h5>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col lg="4" md="8">

                    <Tab.Container defaultActiveKey="best-selling">
                        <Row>

                            <Col lg="12">
                                <Card>
                                    <Card.Body className="p-0">
                                        <div className="mm-edit-list usr-edit">
                                            <Nav variant="pills" className="mm-edit-profile d-flex">
                                                <li className="col-md-6 p-0">
                                                    <Nav.Link eventKey="best-selling">Most Topup Countries</Nav.Link>
                                                </li>
                                                {user?.user?.user?.user?.userModel?.is_admin ? <li className="col-md-6 p-0">
                                                    <Nav.Link eventKey="chang-pwd">Top Vendors</Nav.Link>
                                                </li> : null}

                                            </Nav>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="12">
                                <div className="mm-edit-list-data">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="best-selling" role="tabpanel">
                                            <Card className="card-block card-stretch card-height">
                                                <Card.Header className="card-header-border d-flex justify-content-between">
                                                    <Row>
                                                        <Col lg="12">
                                                            <div className="header-title">
                                                                <h5 className="card-title">Most Topup Selling Countries</h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <div className="card-body-list">

                                                    {isLoading ? (
                                                        <div className="text-center">
                                                            <Spinner animation="border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (
                                                        <ul className="list-style-3 mb-0">
                                                            {mostTopupCountries.map((countryData, index) => (
                                                                <li key={index} className="p-3 list-item d-flex justify-content-start align-items-center">
                                                                    <div className="avatar">
                                                                        <i className="fas fa-flag fa-2x"></i> {/* Globe icon for country */}
                                                                    </div>
                                                                    <div className="list-style-detail ml-3 mr-2">
                                                                        <p className="mb-0 font-weight-bold">{countryData.country}</p>
                                                                        <small className="text-muted">Total Amount: &euro;{countryData.total_amount.toFixed(2)}</small>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>

                                                    )}
                                                </div>
                                            </Card>
                                        </Tab.Pane>
                                        {user?.user?.user?.user?.userModel?.is_admin ? <Tab.Pane eventKey="chang-pwd" role="tabpanel">
                                            <Card className="card-block card-stretch card-height">
                                                <Card.Header className="card-header-border d-flex justify-content-between">
                                                    <Row>
                                                        <Col lg="12">
                                                            <div className="header-title">
                                                                <h5 className="card-title">Best Performing Vendors</h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <div className="card-body-list">

                                                    {isLoading ? (
                                                        <div className="text-center">
                                                            <Spinner animation="border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (
                                                        <ul className="list-style-3 mb-0">
                                                            {mostTopupVendors.map((vendorData, index) => (
                                                                <li key={index} className="p-3 list-item d-flex justify-content-start align-items-center">
                                                                    <div className="avatar">
                                                                        <i className="fas fa-image fa-2x"></i>
                                                                    </div>
                                                                    <div className="list-style-detail ml-3 mr-2">
                                                                        <p className="mb-0">{vendorData.name}</p>
                                                                    </div>
                                                                    <div className="list-style-action d-flex justify-content-end ml-auto">
                                                                        <small className="text-muted">Total Amount: &euro;{vendorData.total_amount.toFixed(2)}</small>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </Card>
                                        </Tab.Pane> : null}


                                    </Tab.Content>
                                </div>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Col>
                {user?.user?.user?.user?.userModel?.is_admin ?
                    <Col lg="8" md="8">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Top Resellers</h4>
                                </div>

                            </Card.Header>
                            <Card.Body className="p-0">
                                <div className="table-responsive">
                                    {isLoading ? (
                                        <div className="text-center">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <table className="table mb-0">
                                            <thead className="table-color-heading">
                                                <tr className="text-secondary">
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Transaction Amount</th>
                                                    <th scope="col">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topUsersTransactionByAmount.map((topTransaction, index) => (
                                                    <tr className="white-space-no-wrap" key={index}>
                                                        <td>{topTransaction.name}</td>
                                                        <td>{topTransaction.company_name}</td>
                                                        <td>€{topTransaction?.total_transaction?.toFixed(2)}</td>
                                                        <td>€{topTransaction?.balance?.toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    : null}
                {user?.user?.user?.user?.userModel?.is_admin ?
                    <Col lg="4" md="4">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Countries Discount</h4>
                                </div>

                            </Card.Header>
                            <Card.Body className="p-0">
                                <div className="table-responsive">
                                    {isLoading ? (
                                        <div className="text-center">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <table className="table mb-0">
                                            <thead className="table-color-heading">
                                                <tr className="text-secondary">
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Discount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {countriesWithDiscount.map((countryDiscount, index) => (
                                                    <tr className="white-space-no-wrap" key={index}>
                                                        <td>{countryDiscount.name}</td>
                                                        <td>{countryDiscount.discount}%</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    : null}
                {user?.user?.user?.user?.userModel?.is_admin ?
                    <Col lg="8" md="8">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Recharge Request</h4>
                                </div>

                            </Card.Header>
                            <Card.Body className="p-0">
                                <div className="table-responsive">
                                    {isLoading ? (
                                        <div className="text-center">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <table className="table mb-0">
                                            <thead className="table-color-heading">
                                                <tr className="text-secondary">
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Reseller</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Requested Amount</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rechargeRequests.map((rechargeRequest, index) => (
                                                    <tr className="white-space-no-wrap" key={index}>
                                                        <td>{rechargeRequest.id}</td>
                                                        <td>{handleDateFormat(rechargeRequest.created_at)}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div>{rechargeRequest.user.name}</div>
                                                            </div>
                                                        </td>
                                                        <td>{rechargeRequest.user.phone}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div>{rechargeRequest.user.user_details.company_name}</div>
                                                            </div>
                                                        </td>
                                                        <td>&euro;{rechargeRequest.requested_amount}</td>

                                                        <td>
                                                            <Link className="" to={`/reseller/wallet-view/${rechargeRequest.user_id}`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 14V6a2 2 0 00-2-2H6a2 2 0 00-2 2v8m16 0h2m0 0h-2m0 0h-2m0 0h2m-2 0a4 4 0 01-4 4H6a4 4 0 01-4-4M4 10h16m4 0a6 6 0 01-6 6H8a6 6 0 01-6-6m16 0v4m0 0a2 2 0 01-2 2H6a2 2 0 01-2-2m0 0a6 6 0 016-6h4a6 6 0 016 6z"></path>
                                                                </svg>

                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}

                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <Link to="recharge-request" className='btn btn-primary float-right'>View All</Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    : null}
                {user?.user?.user?.user?.userModel?.is_admin ?
                    <Col lg="4" md="4">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Operator Discount</h4>
                                </div>

                            </Card.Header>
                            <Card.Body className="p-0">
                                <div className="table-responsive">
                                    {isLoading ? (
                                        <div className="text-center">
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <table className="table mb-0">
                                            <thead className="table-color-heading">
                                                <tr className="text-secondary">
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Discount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {operatorsWithDiscount.map((operatorDiscount, index) => (
                                                    <tr className="white-space-no-wrap" key={index}>
                                                        <td>{operatorDiscount.name}</td>
                                                        <td>{operatorDiscount.discount}%</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    : null}

            </Row >
        </Container >
    )
}

export default Dashbord;